export const isBlank = (val: string | undefined | null) => {
    if (val === null || val === undefined || val.length === 0) return true;
    return false;
}

export const isNotBlank = (val: string | undefined | null) => {
    return !isBlank(val);
}

export const defaultVal = (dVal: string, val?: string): string => {
    return isBlank(val) ? dVal : val + '';
}