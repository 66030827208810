import { maxAllowedChoresPerDayForAdult, maxAllowedChoresPerDayForGradeSchooler, maxAllowedChoresPerDayForPreschool, maxAllowedChoresPerDayForTeen } from "./constants";
import { IHash } from "../../../../interfaces/common";
import { DbId } from "../../common";

export interface VotingSettings extends DbId {
    autoStartCountDown: boolean;
    showVotingCountDownNHoursBefore: number;

    maxAllowedChoresPerDayForPreschool: number;
    maxAllowedChoresPerDayForGradeSchooler: number;
    maxAllowedChoresPerDayForTeen: number;
    maxAllowedChoresPerDayForAdult: number;

    votingStartsAt: string;//army time
    votingStartsOnIndex: number;
    votingEndsAt: string;//army time
    votingEndsOnIndex: number;
}

export interface BasicAppSettings {
    showDisabledFeatures: boolean;
}

export enum AppSettingTypes {
    General = 'General'
}

export interface AppSettings extends DbId {
    settings?: BasicAppSettings;
    votingConfig: VotingSettings;
}

export let updateSettingsField = (key: string, val: any): IHash => {
    let update: IHash = {};
    update[`settings.${key}`] = val;

    return update;
}

export let updateVotingConfigField = (key: string, val: any): IHash => {
    let update: IHash = {};
    update[`votingConfig.${key}`] = val;

    return update;
}
export let stubWeekSettings = () => {
    let settings: VotingSettings = {
        createdOn: new Date().toISOString(),
        docId: AppSettingTypes.General,
        modifiedOn: new Date().toISOString(),

        autoStartCountDown: true,

        maxAllowedChoresPerDayForPreschool: maxAllowedChoresPerDayForPreschool,
        maxAllowedChoresPerDayForGradeSchooler: maxAllowedChoresPerDayForGradeSchooler,
        maxAllowedChoresPerDayForTeen: maxAllowedChoresPerDayForTeen,
        maxAllowedChoresPerDayForAdult: maxAllowedChoresPerDayForAdult,

        showVotingCountDownNHoursBefore: 2,
        votingStartsAt: "21:00",
        votingEndsAt: "21:00",
        votingStartsOnIndex: 5,
        votingEndsOnIndex: 6
    }
    return settings;
}

export let stubGeneralSettings = () => {
    let settings: AppSettings = {
        createdOn: new Date().toISOString(),
        docId: AppSettingTypes.General,
        modifiedOn: new Date().toISOString(),


        settings: {
            showDisabledFeatures: false,
        },
        votingConfig: stubWeekSettings(),
    }

    return settings;
}
