import {
    WhereFilterOp, DocumentReference, QueryDocumentSnapshot, DocumentData, FirestoreDataConverter, WithFieldValue, CollectionReference, QuerySnapshot, Query,
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    orderBy,
    limit,
    getDoc,
    DocumentSnapshot,
    doc,
    increment,
    writeBatch,
    setDoc,
} from "firebase/firestore";
// import {
//     ​​  getFirestore,
//     ​​  query,
//     ​​  getDocs,
//     ​​  collection,
//     ​​  where,
//     ​​  addDoc,
//     ​​} from "firebase/firestore";

import { Firebase } from "../../config";
import { docDateFields, docDeepFields, toFirebaseTimestamp, toReduxTimestamp } from "../../config/date";
import { Logger } from "../../logger";
import { isBlank } from "../../utils";
import { CollectionStats, statsDocId } from "./common";
import { isDocRef } from "./type-guards";
// import { retryConnection } from "~utils/retryUtil";
// import { DEFAULT_ERRORS, retryWhen } from "~utils/retryUtil";

/**
 * https://fireship.io/snippets/firestore-increment-tips/
 */

export type onAsyncActionFunc<T> = (data: T) => Promise<boolean | T>;
export let enableStatsDoc = false;
export let enableForceLongPolling = false;
export const MAX_BATCH_SIZE = 500;
let logger = new Logger('Util');


export interface FirebaseConfig {
    docId?: string;
    limit?: number;
    reducerName: string;
    runPostProcessor?: boolean;
    tryDocId?: boolean;
    // useReduxRead: boolean;
    where?: WhereOption[];
    mergeData?: boolean;
}

export interface WhereOption {
    fieldName: string;
    operator: WhereFilterOp;
    fieldValue: any;
}

export let whereOperator = (fieldName: string = 'docId', fieldValue: any, operator: WhereFilterOp = '=='): WhereOption => {
    return {
        fieldName,
        fieldValue,
        operator
    }
}


export type createCallback<T> = (docRef: DocumentReference<T>) => T;
// export let forceLongPolling = (_: number) => {
//     if (enableForceLongPolling) return;
//     firebase.firestore().settings({ experimentalForceLongPolling: true });
//     enableForceLongPolling = true;
// }


// const nonSerializableNumbers = [
//     'numberOfDocs',
// ]

const MAX_MASSAGE_DEPTH = 1;

export function massage<T>(data: T, deep: number = 0) {
    if (deep > MAX_MASSAGE_DEPTH) return data;
    let map: any = data;
    for (let i = 0; i < docDateFields.length; i++) {
        const key = docDateFields[i];

        if (map[key]) {
            let val = map[key];
            // fromServerTimestamp(val) ??
            map[key] = toReduxTimestamp(val) ?? {
                nanoseconds: val.nanoseconds,
                seconds: val.seconds
            }
        }
    }

    for (let j = 0; j < docDeepFields.length; j++) {
        const key = docDeepFields[j];

        if (map[key]) {
            map[key] = massage(map[key], deep + 1);
        }
    }

    return data;
}

export function convertToFirestore<T>(data: T) {
    let map: any = Object.assign({}, data);
    for (let i = 0; i < docDateFields.length; i++) {
        const key = docDateFields[i];

        if (map[key]) {
            let val = map[key];
            // fromServerTimestamp(val) ??
            map[key] = toFirebaseTimestamp(val);
        }
    }
    return map;
}


function filterStatsDoc<T>(snap: QueryDocumentSnapshot) {
    let data: DocumentData = snap.data();
    if (data && data.docId == statsDocId) {
        return null;
    }

    return massage(data) as T
}


// Firestore data converter
export const converter = <T>() => {
    return {
        toFirestore: (data: any) => convertToFirestore(data),
        fromFirestore: (snapshot: any) => filterStatsDoc(snapshot) as T
    }
}


// export const statsConverter = <T>() => ({
//     toFirestore: (data: Partial<T>) => data,
//     /// update to account for stats doc
//     fromFirestore: (snap: QueryDocumentSnapshot) => massage(snap.data()) as T
// });

// const fromTimestamp = (timestamp: Timestamp) => {
//     let miliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
//     return new Date(miliseconds);
//     // let d = Date.UTC(0, 0, 0, 0, 0, 0, miliseconds);
// }


// export const updateCallback = <T extends DbId>(docRef: DocumentReference<T>, data: T) => {
//     data.docId = docRef.id;
//     data.modifiedOn = FieldValue.serverTimestamp();

//     if (!data.createdOn) {
//         data.createdOn = FieldValue.serverTimestamp();
//     } else {
//         data.createdOn = toFirebaseTimestamp(data.createdOn);
//     }
//     return data;
// };

export const dataPoint = <T>(collectionPath: string) => {
    try {
        return collection(Firebase.db, collectionPath)
            .withConverter(converter<T>());
        // .withConverter(enableStatsDoc && includeStats ? statsConverter<T>() : converter<T>());
    } catch (error) {
        logger.debug(error);
        throw error;
    }
}


// export async function collectionHasData(collectionPath: string | null) {
//     try {
//         // let size = await fbase.db
//         //     .collection(collectionPath!)
//         //     .limit(1)
//         //     .get()
//         //     .then(query => query.size);
//         // return size > 0;
//     } catch (error) {
//         logger.warn(error);
//     }
//     return null
// }

export const populateDocReferences = async <T>(data: T | null) => {
    if (!data) return data;

    let doc = data as any;
    for (let [key, value] of Object.entries(data)) {
        // logger.log(`${key}: ${value}`);

        if (isDocRef(value)) {
            let snap: DocumentSnapshot<any> | null = await getDoc(value);
            if (snap && snap.exists()) {
                // logger.log(`${key}: DocRef`);
                doc[key] = massage(snap.data()) || null;
                // we could have nested docRefs.. lets not worry about it now.. and try to avoid doing so.
                // but if we do.. should can address it here..
            }
        }
    }

    return doc;
}

const postProcessData = async <T>(items: QueryDocumentSnapshot<T>[] | null, runPostProcessor = false) => {
    if (!runPostProcessor) {
        return (items || []).map(doc => doc.data()) || [];
    }

    const promises = (items || []).map(async item => {
        return await populateDocReferences(item.data())
    });

    logger.debug(`Util:postProcessData -> before making call;`);
    let processItems: T[] = await Promise.all(promises)
    logger.debug(`Util:postProcessData -> after making call; processItems.length=${processItems.length}`);

    return processItems;
}

export async function get<T>(id: string, collection: CollectionReference<T>): Promise<T | null> {
    let data: DocumentSnapshot<T> | null = null;
    try {
        // data = await retryConnection(async () => await collection.doc(id).get());

        const docRef = doc(collection, id);
        data = await getDoc(docRef);
    } catch (error) {
        logger.warn(`Failed to retrieve data.`, error);
        throw error;
    }

    if (data && data.exists()) {
        // logger.debug(`Data found`);
        return ((await populateDocReferences(data.data()) as T) || null);
    }
    return null;
}

// async function getRef<T>(id: string, collection: CollectionReference<T>): Promise<DocumentReference<T> | null> {
//     try {
//         if (isBlank(id)) {
//             return collection.doc();
//             // return await retryConnection(async () => collection.doc());
//         }
//         // return await retryConnection(async () => collection.doc(id));
//         return collection.doc(id);
//     } catch (error) {
//         logger.warn(`Failed to retrieve data.`, error);
//         throw error;
//     }
// }

// async function getDocFrom<T>(id: string, source: 'default' | 'server' | 'cache', collection: CollectionReference<T>): Promise<T | undefined> {
//     try {
//         if (isBlank(id)) {
//             return undefined;
//         }
//         logger.debug(`util:getDocFrom -> before calling collection.doc().get; source=${source}`);
//         let snapshots = await collection.doc().get({
//             source: source
//         });

//         let data = snapshots.exists ? snapshots.data() : undefined;
//         logger.debug(`util:getDocFrom -> after calling collection.doc().get; source=${source}`);

//         return data;
//     } catch (error) {
//         logger.warn(`Failed to retrieve data.`, error);
//         throw error;
//     }
// }

export async function find<T>(collection: CollectionReference<T>, clause: WhereOption[], limitBy: number = NaN, runPostProcessor = false): Promise<T[]> {
    let data: QuerySnapshot<T> | null = null;
    // limit = limit <= 0 ? 1 : limit;

    try {
        let q: Query<T> | CollectionReference<T> = collection;
        if (clause.length) {
            for (let i = 0; i < clause.length; i++) {
                const filter = clause[i];
                q = query(collection, where(filter.fieldName, filter.operator, filter.fieldValue));
            }
        }


        // logger.debug(`filters: `, clause);

        if (isNaN(limitBy) || limitBy <= 0) {
            data = await getDocs(q);
        } else {
            q = query(q, orderBy("docId", "asc"), limit(limitBy));
            data = await getDocs(q);
            // logger.debug(`Util:query.limit(limit).get() -> after making call; limits=${limit}`);
        }
    } catch (error) {
        logger.warn(`Failed to retrieve data.`, error);
        throw error;
    }

    return extractDoc(data, runPostProcessor);
}

export async function extractDoc<T>(data: QuerySnapshot<T> | null, runPostProcessor: boolean) {
    if (data && data.size) {
        // logger.debug(`Data found`, data.size);
        //update
        if (enableStatsDoc) {
            let items = await postProcessData(data.docs, runPostProcessor) || [];
            return items.filter(x => x != null);
        }
        logger.debug(`Util:extractDoc -> before making call;`);
        return await postProcessData(data.docs, runPostProcessor) || [];
    }
    return [];
}

// export async function extractDocFromRef<T>(docRef: DocumentReference<T>) {
//     let data: DocumentSnapshot<T> = await docRef.get();

//     if (data && data.exists) {
//         // logger.debug(`Data found`);
//         return ((await populateDocReferences(data.data()) as T) || null);
//     }
//     return null;
// }

export async function firstOccurrence<T>(collection: CollectionReference<T>, clause: WhereOption[], limit: number = 1, suppressError = false, runPostProcessor = false): Promise<T | null> {
    try {
        let result = await find<T>(collection, clause, limit, runPostProcessor);
        return result.length ? result[0] : null;
    } catch (error) {
        if (suppressError) return null;
        throw error;
    }
}

export async function update<T>(collection: CollectionReference<T>, id: string, callback: createCallback<T>, isNew: boolean = false): Promise<T> {
    let data: T | null = null;
    let collectionWithStats = dataPoint<CollectionStats>(collection.path);

    try {
        let docRef = isBlank(id) ? doc(collection) : doc(collection, id);
        data = callback(docRef);

        if (isNew && enableStatsDoc) {
            // try {
            const statsRef = doc(collectionWithStats, statsDocId);
            const incrementBy = increment(1);
            const statsDoc: CollectionStats = { numberOfDocs: incrementBy, docId: statsDocId };

            const batch = writeBatch(Firebase.db);
            batch.set(statsRef, statsDoc, { merge: true });
            batch.set(docRef, data!, { merge: true });
            await batch.commit();

            logger.debug(`util -> update succeeded`, statsDoc);
            // } catch (error) {
            //     await docRef.set(data!, { merge: true });
            //     logger.warn(error);
            // }
            // logger.debug(`util -> update succeeded`, statsDoc);
        } else {
            await setDoc(docRef, data!, { merge: true })
        }
        // collection.path
        logger.debug(`util -> update succeeded`);
    } catch (error) {
        logger.warn(error);
        throw error;
    }

    return (await populateDocReferences(data)) as T;
}

// async function updateAll<T>(rawData: IHash, collection: CollectionReference<T>) {
//     try {
//         // return collection.get().then((snapshot) => {
//         //     return Promise.all(snapshot.docs.map(async doc => {
//         //         await doc.ref.update(rawData);
//         //         return doc.data();
//         //     }));
//         // });
//     } catch (error) {
//         logger.warn(`Failed to update data.`, error);
//         return [];
//     }
// }



// async function updateDoc<T>(docId: string, rawData: IHash, collection: CollectionReference<T>): Promise<boolean> {
//     try {
//         let docRef = collection.doc(docId);
//         await docRef.update(rawData);
//         logger.debug(`Util:updateDoc -> document fields with docId=${docId} updated.`, rawData);
//     } catch (error) {
//         logger.warn(`Util:updateDoc -> Failed to update fields in document.`, error);
//         return false;
//     }
//     return true;
// }

// async function deleteDoc<T>(id: string, collection: CollectionReference<T>): Promise<boolean> {
//     try {
//         let docRef = collection.doc(id);
//         let doc = await docRef.get();

//         if (doc.exists) {
//             if (enableStatsDoc) {
//                 //TODO: implement
//                 await docRef.delete();
//             } else {
//                 await docRef.delete();
//             }
//         } else {
//             return true;
//         }
//     } catch (error) {
//         try {
//             return !(await collection.doc(id).get()).exists;
//         } catch (error) {
//             logger.info(`Failed to retrieve data.`, error);
//         }
//         logger.warn(`Failed to delete data.`, error);
//         return false;
//     }

//     return true;
// }

// export const updateDocDefaults = <T extends DbId>(data: T, docRef: DocumentReference<T>) => {
//     data.docId = docRef.id;
//     data.modifiedOn = FieldValue.serverTimestamp();

//     if (!data.createdOn) {
//         data.createdOn = FieldValue.serverTimestamp();
//     } else {
//         // logger.debug(`util:updateDocDefaults -> data.createdOn `, data.createdOn);
//         // logger.debug(`util:updateDocDefaults -> toFirebaseTimestamp(data.createdOn) `, toFirebaseTimestamp(data.createdOn));
//         data.createdOn = toFirebaseTimestamp(data.createdOn);
//     }
//     return data;
// }

// export const sendRequest = async <T extends DbId>(path: string, data: T, cb: onAsyncActionFunc<T>) => {
//     let collection = dataPoint<T>(path!)
//     let isNew = !data.createdOn;
//     let result = await update(collection, data.docId, (docRef) => {
//         return updateDocDefaults(data, docRef);
//     }, isNew);

//     return await cb(result);
// }

// export const removeDoc = async <T extends DbId>(path: string, data: T, cb: onAsyncActionFunc<T>) => {
//     let collection = dataPoint<T>(path!)
//     let result = await deleteDoc(data.docId, collection);

//     if (result) {
//         return await cb(data);
//     }
//     return result;
// }


// export const batchEntries = async <T extends DbId>(collectionPath: string, data: T[]) => {
//     logger.debug(`Batching entries... Total: ${data.length} MAX_BATCH_SIZE: ${MAX_BATCH_SIZE}; Collection name: ${collectionPath}`);

//     try {
//         let i, j, chunk, chunkSize = MAX_BATCH_SIZE;
//         for (i = 0, j = data.length; i < j; i += chunkSize) {
//             chunk = data.slice(i, i + chunkSize);
//             await _batchEntries(collectionPath, chunk);
//         }

//     } catch (error) {
//         logger.debug(error);
//         throw error;
//     }
// }

// const _batchEntries = async <T extends DbId>(collectionPath: string, items: T[]) => {
//     try {
//         logger.debug(`Attempting to batch-write ${items.length} items to ${collectionPath}`);
//         let collection = dataPoint<T>(collectionPath);
//         const batch = fbase.db.batch();

//         for (let index = 0; index < items.length; index++) {
//             const item = items[index];
//             let data = Object.assign({}, item);
//             let docRef = isBlank(item.docId) ? collection.doc() : collection.doc(item.docId);

//             data.docId = docRef.id;

//             data.modifiedOn = FieldValue.serverTimestamp();
//             data.createdOn = FieldValue.serverTimestamp();

//             batch.set(docRef, data, { merge: true });
//         }
//         await batch.commit();
//         logger.debug(`Successfully batch-write ${items.length} items to ${collectionPath}`);
//     } catch (error) {
//         logger.debug(`Failed to batch-write ${items.length} items to ${collectionPath}`);

//         logger.debug(error);
//         throw error;
//     }
// }


export const db = {
    // batchEntries,
    dataPoint: dataPoint,
    // delete: deleteDoc,
    get: get,
    // getRef: async <T>(id: string, path: string) => {
    //     let collection = dataPoint<T>(path!)
    //     return await getRef(id, collection);
    // },
    find: find,
    firstOccurrence: firstOccurrence,
    // sendRequest: sendRequest,
    update: update,
    // updateDoc: updateDoc,
    // updateAll,
    // testConnection: async () => {
    //     let collection = dataPoint<any>(commonPaths.testConnections)
    //     logger.debug(`util:testConnection -> before calling getDocFrom`);
    //     return await getDocFrom('test-connection', 'server', collection);
    // }
}

