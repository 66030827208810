import * as React from 'react';
import Box from '@mui/material/Box';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, Button, Link, Stack } from '@mui/material';
import { BackgroundLogo } from '../../../assets';
import { useEffect } from 'react';
import { subscribe } from '../../../utils';
import { intl } from '../../../translations';
import { Firebase } from '../../../config';
import LogoutIcon from '@mui/icons-material/Logout';
import { Style } from '../../../styling';
import { LoadingBox } from '../../atom/common/loading';

const buttonStyle: React.CSSProperties = {
    border: "0.5px solid black",
    textTransform: 'none',
    width: '140pt',
}
const iconStyle: React.CSSProperties = {
    marginRight: Style.matGrid * 2
};

export function AuthLogout() {

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [isLoading, setIsLoading] = React.useState(false);
    const [actionTriggered, setActionTriggered] = React.useState<boolean>(false);

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        if (!actionTriggered) return;

        return subscribe(async () => {
            await Firebase.auth.signOut();
            return true;
        }, async (_result) => {
            setIsLoading(false);
            setActionTriggered(false);
        });
    }, [actionTriggered])

    //------------------------------------------------
    // Handlers
    //------------------------------------------------
    const handleLogout = () => {
        setIsLoading(true);
        setActionTriggered(true);
    }
    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box className="heading-container">
                <h3>
                    {intl('titles.logout')}
                </h3>
            </Box>
            <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
            >
                <Link href='/'>
                    <BackgroundLogo></BackgroundLogo>
                </Link>
            </Box>
            <Stack
                direction="row"
                spacing={2}>
                <Button
                    onClick={() => handleLogout()}
                    style={buttonStyle}
                >
                    <LogoutIcon fontSize='medium' style={iconStyle} />
                    {intl('buttons.common.logout')}
                </Button>
            </Stack>
            <LoadingBox loading={isLoading} />
            <ToastContainer />
        </Box>
    );
}