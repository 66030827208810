


//https://www.sessions.edu/color-calculator-results/?colors=ffa476,c61f0d,11acff,76ffab
//https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=FFA476&secondary.color=11acff
//https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=11acff
//https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=11acff&primary.text.color=000000

const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const accentColor = "#FFA476";
export const customTheme = {
    roundness: 30,
    colors: {
        primary: "#11acff",
        accent: accentColor,
        secondary: '#c61f0d',
        secondaryAccent: '#76ffab',
        primaryLight: '#6cddff',
        primaryDark: '#007dcb',
        accentLight: '#ffd6a5',
        accentDark: '#c97549',
        hairline: '#CECECE',
        offWhite: '#fcfcfc',


        // primary: "#76cfff",
        // primary: "#4169E1",
        // accent: "#f1c40f",
        //#11acff
        //
        tetradic: {
            primary: '#ffa476',
            secondary: '#c61f0d',
            accent: '#11acff',
            secondaryAccent: '#76ffab',
        },
        largeText: {
            primary: '#FFFFFF',
            secondary: 'rgba(0,0,0,.48)'
        },
        button: {
            backgroundColor: '#11acff',
            color: '#000000',
            altColor: '#FFFFFF',
            // disabled: '#9ea7ad'//'#EBEBEB',
            disabled: '#EBEBEB',
        },
        buttonText: {
            focusBg: '#DCDCDC',
            focusLine: 'rgba(0,0,0,.87)',
            hoversBg: '#E8E8E8',
            hoversBgLine: 'rgba(0,0,0,.87)',
            placeholderBg: '#f5f5f5',
            placeholderLine: 'rgba(0,0,0,.42)',
            disabled: '#666666'
        },

        dropDownItem: {
            bgColor: '#FFF',
            ripple: '#E8E8E8',
            border: '#ccc',
        },

        light: {
            text: '#000',
            background: '#fff',
            tint: tintColorLight,
            tabIconDefault: '#ccc',
            tabIconSelected: tintColorLight,

            tabBarActiveTintColor: '#000',
            tabBarActiveBackgroundColor: accentColor,
            tabBarInactiveTintColor: '#000',

            appleColor: '#fff',
            appleBackgroundColor: '#1c1c1e',
            facebookColor: '#fff',
            facebookBackgroundColor: '#3b5998',
            googleColor: '#fff',
            googleBackgroundColor: '#dd4b39',
            //#E8E8E8
        },
        dark: {
            text: '#fff',
            background: '#000',
            tint: tintColorDark,
            tabIconDefault: '#ccc',
            tabIconSelected: tintColorDark,
            tabBarActiveTintColor: '#000',
            tabBarActiveBackgroundColor: tintColorDark,
            tabBarInactiveTintColor: '#000',
            appleColor: '#000',
            appleBackgroundColor: '#fff',
            facebookColor: '#000',
            facebookBackgroundColor: '#fff',
            googleColor: '#000',
            googleBackgroundColor: '#fff',
        },

        status: {
            critical: '#ff3838',
            serious: '#ffb302',
            caution: '#fce83a',
            normal: '#56f000',
            standby: '#2dccff',
            off: '#9ea7ad',
        },
    },
};

// export default theme;




// .apple-button {
//   // background-color: #a6b1b7;
//   background-color: #1c1c1e;
//   color: #ffffff;
// }
/*
https://www.astrouxds.com/patterns/status-system/
Hex	RGB	CSS	Synonyms
Status Color: Critical 	#ff3838	255,56,56	--colorCritical	Critical, alert, emergency, urgent
Status Color: Serious 	#ffb302	255,179,2	--colorSerious	Serious, error, warning, needs attention
Status Color: Caution 	#fce83a	252,232,58	--colorCaution	Caution, unstable, unsatisfactory
Status Color: Normal 	#56f000	86,240,0	--colorNormal	Normal, on, ok, fine, go, satisfactory
Status Color: Standby 	#2dccff	45,204,255	--colorStandby	Standby, available, enabled
Status Color: Off 	#9ea7ad	158,167,173	--colorOff	Off, unavailable, disabled

Social Media Brand Colors

twitter:     #00aced     rgb(0, 172, 237)
facebook:    #3b5998     rgb(59, 89, 152)
googleplus:  #dd4b39     rgb(221, 75, 57)
pinterest:   #cb2027     rgb(203, 32, 39)
linkedin:    #007bb6     rgb(0, 123, 182)
youtube:     #bb0000     rgb(187, 0, 0)
vimeo:       #1ab7ea     rgb(26, 183, 234)
tumblr:      #32506d     rgb(50, 80, 109)
instagram:   #bc2a8d     rgb(188, 42, 141)
flickr:      #ff0084     rgb(255, 0, 132)
dribbble:    #ea4c89     rgb(234, 76, 137)
quora:       #a82400     rgb(168, 36, 0)
foursquare:  #0072b1     rgb(0, 114, 177)
forrst:      #5B9A68     rgb(91, 154, 104)
vk:          #45668e     rgb(69, 102, 142)
wordpress:   #21759b     rgb(33, 117, 155)
stumbleupon: #EB4823     rgb(235, 72, 35)
yahoo:       #7B0099     rgb(123, 0, 153)
blogger:     #fb8f3d     rgb(251, 143, 61)
soundcloud:  #ff3a00     rgb(255, 58, 0)
Apple        #a6b1b7     RGB: 166, 177, 183
rgb(28,28,30)

*/
/*


*/