import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SubscriptionDetails, SubscriptionPlanPurchased, SubscriptionPlans } from "../components";
import { isBlank, subscribe } from "../utils";
import { useAuthContext } from "../providers";
import { subDto, SubscriptionDto } from "../model-interfaces";
import { userService } from "../services/account/user-service";
import { intl } from "../translations";
import { ErrorObject, SubscriptionEnum } from "../interfaces";
import { priceDto, PriceDto } from "../model-interfaces/price-dto";
import { LoadingBox } from "../components/atom/common/loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Logger } from "../logger";

let logger = new Logger(SubscriptionsPage.name);

export function SubscriptionsPage() {
    //------------------------------------------------
    // Hook
    //------------------------------------------------
    let { token } = useAuthContext();

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [isLoading, setIsLoading] = React.useState(true);
    const [subscription, setSubscription] = React.useState<SubscriptionDto>(subDto);
    const [standardPrice, setStandardPrice] = React.useState<PriceDto>(priceDto);
    const [platniumPrice, setPlatniumPrice] = React.useState<PriceDto>(priceDto);
    const [customerPortalUrl, setCustomerPortalUrl] = React.useState<string>('');
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        if (!isLoading) return;
        return subscribe(async () => {

            let prices = await userService.getPrices(token) ?? [];
            let url = await userService.getPortalurl(token) ?? [];
            let subs = await userService.getSubscriptions(token);

            return {
                prices,
                subs,
                url
            }
        }, async (result) => {
            let { subs, prices, url } = result ?? {};
            setSubscription(subs ?? subDto);
            setStandardPrice(prices?.find(x => x.subscriptionType === SubscriptionEnum.Standard) ?? priceDto);
            setPlatniumPrice(prices?.find(x => x.subscriptionType === SubscriptionEnum.Platnium) ?? priceDto);
            setCustomerPortalUrl(url ?? '');
            setIsLoading(false);
        });
    });

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        logger.debug(`SubscriptionsPage:Session -> query=${query}`);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id') ?? '');
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(intl('pages.subscription.orderCancelled'));
        }
        if (query.get('badrequest')) {
            setSuccess(false);
            setMessage(intl('error.generic.unknown'));
        }
    }, [sessionId]);

    //------------------------------------------------
    // Handers
    //------------------------------------------------
    let handlerError = (error: ErrorObject) => {
        toast.error(error.error);
    }
    //------------------------------------------------
    // Renders
    //------------------------------------------------
    if (isLoading) {
        return <LoadingBox loading={isLoading} />;
    } else if (!success && isBlank(message)) {
        return (
            <Box>
                <SubscriptionDetails subscription={subscription} />
                <SubscriptionPlans
                    token={token}
                    customerPortalUrl={customerPortalUrl}
                    subscription={subscription}
                    standardPrice={standardPrice}
                    platniumPrice={platniumPrice}
                    onError={handlerError} />
                <ToastContainer />
            </Box>
        );
    } else {
        return (
            <Box>
                <SubscriptionPlanPurchased
                    sessionId={sessionId}
                    token={token}
                    message={message}
                    purchasePlan={subscription.subscriptionType}
                    onError={handlerError} />
                <SubscriptionDetails subscription={subscription} />
                {isBlank(sessionId) ? <SubscriptionPlans
                    token={token}
                    customerPortalUrl={customerPortalUrl}
                    subscription={subscription}
                    standardPrice={standardPrice}
                    platniumPrice={platniumPrice}
                    onError={handlerError} /> : null}
                <ToastContainer />
            </Box>
        );
    }
};
