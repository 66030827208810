import { User } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { BoolResult } from "../../config";
import { db, Profile, profilePaths, toProfile, whereOperator } from "../../hooks";

async function getOCreateProfile(user: User): Promise<Profile | null> {
    if (!user) return null;

    try {
        let profile = (await _get(user)) || (await _getById(user));
        if (profile) return profile;

    } catch (error) {
        return null;
    }

    let result: BoolResult = await _create(user);

    if (result.isSuccessful) {
        return result.data as Profile;
    }

    return null;
}

async function _get(user: User): Promise<Profile | null> {
    let collection = db.dataPoint<Profile>(profilePaths.user);
    return await db.firstOccurrence<Profile>(collection, [whereOperator('userId', user.uid)], 1);
}

async function _getById(user: User): Promise<Profile | null> {
    let collection = db.dataPoint<Profile>(profilePaths.user);
    return await db.get<Profile>(user.uid, collection);
}

async function _create(user: User): Promise<BoolResult> {
    let collection = db.dataPoint<Profile>(profilePaths.user);
    try {
        let result = await db.update(collection, user.uid, (docRef) => {
            let p = toProfile(user)
            p.docId = docRef.id;
            p.createdOn = serverTimestamp();
            p.modifiedOn = serverTimestamp();

            return p;
        })

        return {
            isSuccessful: true,
            data: result,
        }
    } catch (error) {
        return {
            isSuccessful: false,
            error: error,
        }
    }
}


export const loginService = {
    getOCreateProfile,
}