import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { grey } from '@mui/material/colors';

import { DrawerProps } from '../interfaces';
import { drawerWidth } from '../constants';
import { closedMixin, openedMixin } from './mixins';
import { DrawerHeaderContent } from './drawer-header-content';
import { DrawerContent } from './drawer-content';

const DrawerBody = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
            backgroundColor: grey[200],
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export const Drawer = (props: DrawerProps) => {
    const {
        open = false,
        pic,
        displayName,
        onDrawerClose = () => { },
        onDrawerOpen = () => { },
    } = props;

    return (
        <DrawerBody variant="permanent" open={open}>
            <DrawerHeaderContent
                pic={pic}
                displayName={displayName}
                onDrawerClose={onDrawerClose} onDrawerOpen={onDrawerOpen} />
            <Divider />
            <DrawerContent
                open={open} onDrawerOpen={onDrawerOpen} />
        </DrawerBody>
    );
};
