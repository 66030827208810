import { Logger } from "../logger";
import { onVoidFunc } from "./func";
// import {unstable_batchedUpdates} from 'react-dom';
// react-native

export type asyncVoidFunc<T> = (data: T) => Promise<void>;
export type asyncActionFunc<T> = () => Promise<T>;
export type isValidFunc = () => boolean;

let logger = new Logger('Callback');

export let subscribe = <T>(cb: asyncActionFunc<T>, done: asyncVoidFunc<T | null>, errorCb?: onVoidFunc, unsubscribe?: onVoidFunc) => {
    let isCancelled = false;

    const fetchData = async () => {
        try {
            let data = await cb();

            if (!isCancelled) {
                await done(data);
            }
        } catch (error) {
            logger.debug(`======================Begining Encountered an error ==================================`);
            logger.debug(`isCancelled=${isCancelled}`);
            logger.debug(`Error`, error);

            logger.debug(`======================End of Encountered an error ==================================`);

            if (!isCancelled) {
                if (errorCb) {
                    errorCb(error);
                } else {
                    await done(null);
                }
            }
        }
    };
    fetchData()

    return () => {
        isCancelled = true;

        try {
            if (unsubscribe) { unsubscribe(); }
        } catch (error) {
            logger.warn(`util:callback:unsubscribe:event:subscribe -> Remove code thats directly connected to another useEffect  \n`, error);
        }
    };
}
