import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { DrawerProps } from '../interfaces';
import { Link } from "react-router-dom";
import { routes } from '../../../navigation';

export const DrawerContent = (props: DrawerProps) => {
    const { open = false } = props;

    return (
        <List>
            {routes.filter(x => x.path).map((route, index) => (
                <ListItem
                    key={route.title}
                    disablePadding
                    sx={{
                        display: 'block',
                        underline: 'none'
                    }}
                    component={Link}
                    to={route.path!}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {route.iconElement ? route.iconElement : <InboxIcon />}
                        </ListItemIcon>
                        <ListItemText primary={route.title} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};
