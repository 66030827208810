import { Logger } from "../logger";

export let noopFunc = () => { };


export type boolEventFunc = (event: boolean) => void;
export type voidEventFunc = () => void;
type numberEvent = (event: number) => void;


export type onItemDeletedFunc = boolEventFunc;
export type onItemSelectionFunc = numberEvent
export type onActionFunc<T> = (data: T) => void;
export type onVoidFunc = (data?: any) => void;

export type boolFunc<T> = (data: T) => Promise<boolean>;
export type boolWithTFunc<T> = (data: T) => Promise<boolean | T>;
export type onAsyncVoidFunc = (data?: any) => PromiseLike<void> | void;
export type onAsyncFunc<T> = () => PromiseLike<T> | T;
export type progressFunc = (percentage: number) => void;
let logger = new Logger('Func');



export const suppressError = async <T>(cb: onAsyncFunc<T>) => {
    try {
        return await cb();
    } catch (error) {
        logger.info(error);
        logger.warn(error);
    }
}

