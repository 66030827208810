import { Timestamp } from "./common";
import { PlannerTabTypes } from "./enums";

export const PlannerTaskMapping = new Map<PlannerTabTypes, string>([
    [PlannerTabTypes.activity, "activity"],
    [PlannerTabTypes.chores, "chore"],
    [PlannerTabTypes.meals, "meal"],
    [PlannerTabTypes.summary, "summary"],
]);

export const mapToPlannerTabTypes = new Map<string, PlannerTabTypes>([
    ["activity", PlannerTabTypes.activity],
    ["chore", PlannerTabTypes.chores],
    ["meal", PlannerTabTypes.meals],
    ["summary", PlannerTabTypes.summary],
]);

export const IconTaskMapping = new Map<PlannerTabTypes, string>([
    [PlannerTabTypes.activity, "event"],//MaterialIcons
    [PlannerTabTypes.chores, "clean-hands"],//MaterialIcons
    [PlannerTabTypes.meals, "restaurant-menu"],//MaterialIcons
    [PlannerTabTypes.summary, "summary"],
]);

export const fromTimestamp = (timestamp: Timestamp) => {
    let miliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    return new Date(miliseconds);
    // let d = Date.UTC(0, 0, 0, 0, 0, 0, miliseconds);
}