import * as React from 'react';
import { Box, Divider, Link, Stack } from '@mui/material';
import { theme } from '../../../styling';
import { Copyright } from './copyright';

export function Footer() {
    return (
        <Box sx={{
            height: 80,
            backgroundColor: theme.palette.grey[900],
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
        }}>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem light sx={{
                    borderColor: theme.palette.grey[600]
                }} />}
                spacing={2}
            >
                <Link href='/policies/privacy-policy.html'>Privacy Policy</Link>
                <Link href='/policies/terms-conditions.html'>Terms & Conditions</Link>
                <Link href='/policies/data-deletion-instructions.html'>Data Deletion Instructions</Link>
                <Copyright></Copyright>

            </Stack>
        </Box>
    );
}

