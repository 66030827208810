import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
    createBrowserRouter,
    RouteObject,
    RouterProvider,
} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './styling';
import ErrorPage from './screens/error-page';
import { HomePage, SubscriptionsPage } from './screens';
import { routes } from './navigation';
import { LoginPage } from './screens';
import { initIntl } from './translations';
import { AuthContainer } from './providers';
import { subscribe } from './utils';
import { BackgroundLogo } from './assets';
import { Logger } from './logger';

// process.env.NODE_ENV === "production" ||
// process.env.REACT_APP_ENV === "STAGING"

// logger.log('Hello world');
// logger.log(`Hello world- testing 1.2.3`);
// https://reactrouter.com/en/main/start/tutorial
let logger = new Logger(Entry.name);
const routeMap: Map<string, React.ReactNode> = new Map([
    ['subscription', <SubscriptionsPage />],
]);

let customRoutes: RouteObject[] = routes.map(x => Object.assign({}, x, { element: routeMap.get(x.path ?? '') }))

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: customRoutes,
    },
    {
        path: "/login",
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/home",
        element: <HomePage />,
        errorElement: <ErrorPage />,
    },
]);
// logger.log('Hello world - before root');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Entry />
        </ThemeProvider>
    </React.StrictMode>
);


function Entry() {
    //------------------------------------------------
    // States
    //------------------------------------------------
    const [isLoading, setIsLoading] = React.useState(true);
    logger.log('Hello world - Entry');

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        if (!isLoading) return;
        return subscribe(async () => {
            return await initIntl();
        }, async (_result) => {
            setIsLoading(false);
        });
    });

    return (
        <React.Fragment>
            {isLoading ?
                <BackgroundLogo /> :
                <AuthContainer>
                    <RouterProvider router={router} />
                </AuthContainer>
            }
        </React.Fragment>
    );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
