import axios from "axios";
import { Endpoints } from "../../constants";
import { Logger } from "../../logger";
import { subDto, SubscriptionDto } from "../../model-interfaces";
import { PriceDto } from "../../model-interfaces/price-dto";

let logger = new Logger('UserService');

async function getSubscriptions(idToken: string | null | undefined) {
    try {
        const response = await axios.get(Endpoints.subscription, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        logger.debug(`UserService:getSubscriptions -> response`, response)
        if (response && response.data) {
            return response.data as SubscriptionDto;
        }
    } catch (error) {
        logger.warn('Authorization failed', error);
    }

    return subDto;
}

async function getPrices(idToken: string | null | undefined): Promise<PriceDto[]> {
    try {
        const response = await axios.get(Endpoints.price, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (response && response.data) {
            return response.data as PriceDto[];
        }
    } catch (error) {
        logger.warn('Authorization failed', error);
    }

    return [];
}


async function getPortalurl(idToken: string | null | undefined): Promise<string> {
    try {
        const response = await axios.get(Endpoints.customerPortal, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (response && response.data) {
            return response.data as string
        }
    } catch (error) {
        logger.warn('Authorization failed', error);
    }

    return '';
}


export const userService = {
    getSubscriptions,
    getPrices,
    getPortalurl,
}