import { Logger } from "../logger";

type asyncActionFunc<T> = () => Promise<T>;
type actionFunc = (retries: number) => void;

let logger = new Logger('RetryUtil');

export const DEFAULT_RETRIES = 1;
export const DEFAULT_ERRORS_CONNECTIONS = [
    'Could not reach Cloud Firestore backend.'.toLowerCase(),
    'Failed to get document because the client is offline.'.toLowerCase()
];
export class RetryablePromise {
    static async retry<T>(retries: number, executor: asyncActionFunc<T>, callback?: actionFunc): Promise<T> {
        return await this.retryWhen(retries, executor, callback);
    }

    static async retryWhen<T>(retries: number, executor: asyncActionFunc<T>, callback?: actionFunc, errorMessages?: string[]): Promise<T> {
        logger.debug(`RetryUtil:retryWhen -> executing code; retries: ${retries}; `);
        let data = await executor();
        logger.debug(`RetryUtil:retryWhen -> executed code completed successfullly; retries: ${retries}; `);
        return data;
    }
}

export async function retryWhen<T>(func: asyncActionFunc<T>, errorMessage: string): Promise<T> {
    return await RetryablePromise.retryWhen(DEFAULT_RETRIES, func, () => { }, [errorMessage]);
}


// export async function retryConnection<T>(func: asyncActionFunc<T>, callback?: actionFunc): Promise<T> {
//     return await RetryablePromise.retryWhen(DEFAULT_RETRIES, func, callback, DEFAULT_ERRORS_CONNECTIONS);
// }


export function isConnectionError(error: any): boolean {
    let errorMsg: string = (error.message || '').toString();
    errorMsg = errorMsg.toLowerCase();

    return error.message && DEFAULT_ERRORS_CONNECTIONS.findIndex((s) => errorMsg.includes(s.toLowerCase())) > -1;
}