// https://timdeschryver.dev/blog/flagged-enum-what-why-and-how#how
//------------------------------------------------
// Functions
//------------------------------------------------
export const enumToBitValues = (enumValue: object) => {
  return Object.keys(enumValue).map(Number).filter(Boolean)
}

/*

const selectedDay = formValueToBit(DaysEnum, [
  true,
  false,
  true,
  false,
  false,
  false,
  false,
])

// output: 5
*/
// export const formValueToBit = (enumeration: object, selection: boolean[]) => {
//     const bits = enumToBitValues(enumeration)
//     return selection.reduce(
//         (total, selected, i) => total + (selected ? bits[i] : 0),
//         0,
//     )
// }

/*
const selectedDay = bitToFormValue(DaysEnum, 5)

output: [
  true,   //  1 & 5
  false,  //  2 & 5
  true,   //  4 & 5
  false,  //  8 & 5
  false,  // 16 & 5
  false,  // 32 & 5
  false,  // 64 & 5
]
*/
// export const bitToFormValue = (enumeration: object, bit: number) => {
//     const bits = enumToBitValues(enumeration)
//     return bits.map((b) => (bit & b) === b)
// }
//------------------------------------------------
// Types & Interfaces
//------------------------------------------------
export interface IItem {
  accessibilityHint: string;
  name: string;
  displayText: string;
}

export interface INamePair {
  abbr: string;
  name: string;
}

export interface ISelected {
  isSelected: boolean;
}


//------------------------------------------------
// Data
//------------------------------------------------
export const maxInputCharacters = 500;
export const maxNumberOfLines = 4;
export const maxFriendlyNameLength = 14;

