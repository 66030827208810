import * as React from 'react';
import { Style } from '../styling';
import logo from '../icon.png';
import logoHand from '../logo_hands.png';
import { Box } from '@mui/material';

interface OptionsProp {
    mt?: number,
    height?: number | string;
    backgroundColor?: string;
}
interface OptionsProp2 {
    marginLeft?: number,
    height?: number | string;
    width?: number | string;
    alignSelf?: string,
    backgroundColor?: string;
    type?: 'normal' | 'hand';
}
export function BackgroundLogo(props: OptionsProp) {
    return (
        <Box sx={{
            alignSelf: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            ...props,
        }}>
            <img src={logo} style={{
                alignSelf: 'center',
                objectFit: 'cover',
                marginBottom: Style.matGridMultiplier,
                height: '40vmin',
                width: '40vmin',

            }} alt="logo" />
        </Box>
    );
}


export function Logo(props: OptionsProp2) {
    return (
        <img src={(props?.type === 'hand' ? logoHand : logo)} style={{
            alignSelf: 'center',
            objectFit: 'cover',
            height: '100%',
            width: '100%',
            ...props,
        }} alt="logo" />
    );
}
