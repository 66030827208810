
let styling = {
    matGrid: 8,
    matGridMultiplier: 1,
    matGridType: 4,
    matGridTypeMultiplier: 0.5,
    // RippleOpacity: 50,
    // profileSize: 56,
    // profileSizeMedium: 64,
    // profileSizeLarge: 80,
    // profileSizeXLarge: 120,
    // badgeRibbonCircleDiameter: 100,
    // badgeRibbonHeight: 140,

    // badgeRibbonCircleDiameterShort: 85,
    // badgeRibbonHeightShort: 126,
}

export const characterWidth = 9.9864;
export const Style = {
    ...styling,
}