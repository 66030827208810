import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export function Copyright() {
    return (
        <Typography variant="body2" color={'#ffffff'} align="center" sx={{
            justifyContent: 'center',
            justifySelf: 'center',
            alignSelf: 'center,'
        }}>
            {'Copyright © '}
            <Link color="inherit" href="/">
                Topia-world
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}