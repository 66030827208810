// import { Timesource } from "~config";
// import { DateFrequency } from "~interfaces";
// import { IconFamily, IHash, IKey, IDictionary } from "~interfaces/common";
// // import Firebase from "~/config/firebase";

import { Timesource } from "../../config/date";
import { DateFrequency } from "../../interfaces/account";
import { IconFamily, IDictionary, IHash, IKey } from "../../interfaces/common";
import * as firestore from "firebase/firestore";

export const statsDocId = '--stats--';
export interface DbId {
    //TODO: Change to firebase.firestore.Timestamp
    createdOn: Timesource;
    docId: string;
    modifiedOn: Timesource;
}

export interface DbItem extends DbId {
    name: string;
    picId: string;
}

export interface DbItemTask extends DbItem {
    category: string[];
    assignTo?: string[];
    details: string;
    difficultyLevel: number;
    estimatedTimeInMins: number;
    frequency: DateFrequency;
    iconFamily?: IconFamily;
    points: number;
    locale: string;
    deletable?: boolean;
}

export interface ItemStats {
    favByNMembers: string[];
    requestedNtimes: number;
    rating: number;
    usageCounter: number;
}

export interface DbFamilyItemTask extends DbItemTask, IHash {
    statistics: ItemStats;
    parentDocId?: string;
}

export interface DbItemTaskIKey extends DbItemTask, IKey {
}
export interface DbFamilyItemTaskIKey extends DbFamilyItemTask, IKey {
}

export interface CollectionStats {
    docId: string;
    numberOfDocs: firestore.FieldValue | number;
}

export interface MemberSelection extends DbId {
    memberDocId: string;
    items: string[];
}

export interface RecurringSelection extends DbId {
    memberSelections: IDictionary<MemberSelection>;
}