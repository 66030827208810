import React, { FormEvent, PropsWithChildren, ReactNode, useEffect } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { isBlank, isNotBlank, onActionFunc, subscribe } from '../../../utils';
import { apiService } from '../../../services';
import { LoadingBox } from './loading';
import { useNavigate } from "react-router-dom";
import { ErrorObject, IHash } from '../../../interfaces';
import { Logger } from '../../../logger';


interface OptionsProp {
    action: string;
    allowRedirect?: boolean
    token: string | null;
    children?: ReactNode | undefined;
    sx?: SxProps<Theme> | undefined

    onSent?: onActionFunc<any>;
    onError: onActionFunc<ErrorObject>;
}
let logger = new Logger(FormBox.name);

export function FormBox(props: OptionsProp) {
    const { token, action, sx,
        onSent, onError,
        allowRedirect = false } = props;
    //------------------------------------------------
    // Hook
    //------------------------------------------------
    let navigate = useNavigate();

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [requestCount, setRequestCount] = React.useState<number>(0);
    const [formData, setFormData] = React.useState<IHash>();
    const [isLoading, setIsLoading] = React.useState(false);

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        if (requestCount === 0 || !formData) return;
        return subscribe(async () => {
            logger.debug(`Form:SendRequest`, token);
            let data = await apiService.postForm(token, formData, action);

            return data;
        }, async (result) => {
            setIsLoading(false);
            setFormData(undefined);
            handleSentRequest(result);
        });
    }, [requestCount]);


    //------------------------------------------------
    // Handler
    //------------------------------------------------
    let handleSentRequest = (result: any) => {
        try {
            if (!isValidResponse(result)) return;

            if (allowRedirect && isNotBlank(result.url)) {
                window.location.replace(result.url);
                return;
            } else if (onSent) {
                onSent(result);
            }
        } catch (error) {
            logger.warn(`Form:handleSentRequest -> encountered an error. result=${result}; `, error)
        }
    }

    let isValidResponse = (result: any) => {
        try {
            logger.debug(`Form:isValidResponse -> result=`, result);
            if (result.rawError) {
                if (result.rawError.response.status === 401) {
                    navigate('/login');
                    return false;
                }
                onError(result as ErrorObject);
                return false;
            }
        } catch (error) {
            logger.warn(`Form:handleSentRequest -> encountered an error. result=${result}; `, error)
        }
        return true;
    }

    //------------------------------------------------
    // Renderer
    //------------------------------------------------
    return (
        <Box
            component="form"
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                let elements = event.currentTarget.elements;
                // let formData = new FormData();
                let formData: IHash = {};

                for (let i = 0; i < elements.length; i++) {
                    let element = elements[i]
                    let name = (element as any).name ?? element.id;
                    if (isBlank(name)) {
                        continue;
                    }

                    // formData.append(name, (element as any).value)
                    formData[name] = (element as any).value;
                }
                setFormData(formData);
                setIsLoading(true);
                setRequestCount(requestCount + 1);
            }}
            method="POST"
            sx={{
                display: 'flex',
                height: '100%',
                ...sx
            }}>
            {props.children}
            <LoadingBox loading={isLoading} />
        </Box>);
};
