import React, { useEffect } from "react";
import { Drawer, NavBarTop } from "./components";
import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import { customTheme, Style } from "./styling";
import logo from './icon.png';
import { subscribe } from "./utils";
import { initIntl, intl } from "./translations";
import { useAuthContext } from "./providers";
import { BackgroundLogo } from "./assets";
import { Logger } from "./logger";


let logger = new Logger(App.name);
export default function App() {

	//------------------------------------------------
	// Hook
	//------------------------------------------------
	let navigate = useNavigate();
	let { user } = useAuthContext();
	//------------------------------------------------
	// States
	//------------------------------------------------
	const [open, setOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);


	logger.log(`App:body -> about to check useAuthContext`);
	// logger.log(`App:body -> about to check useAuthContext. user=`, user);

	//------------------------------------------------
	// Effect
	//------------------------------------------------
	useEffect(() => {
		if (!isLoading) return;
		return subscribe(async () => {
			return await initIntl();
		}, async (result) => {
			setIsLoading(false);
			logger.log(`App:useEffect -> Intl loaded`);
		});
	});

	useEffect(() => {
		if (isLoading) return;
		// if (!user) {
		// 	navigate('/login')
		// } else if (document.location.pathname === '/') {
		// 	//for now.. lets navigate to subscription page.. until we add other pages
		// 	navigate('/subscription')
		// }

		if (document.location.pathname === '/') {
			//for now.. lets navigate to subscription page.. until we add other pages
			navigate('/home')
		}
	}, [isLoading])

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	if (isLoading) {
		return (
			<Box sx={{
				display: 'flex',
				height: '100vh',
				backgroundColor: customTheme.colors.offWhite
			}} className="App">
				<BackgroundLogo />
			</Box>
		);
	}

	return (
		<Box sx={{ display: 'flex' }} className="App">
			<NavBarTop onDrawerOpen={handleDrawerOpen} open={open} />
			<Drawer
				onDrawerClose={handleDrawerClose}
				onDrawerOpen={handleDrawerOpen} open={open}
				pic={user?.photoURL}
				displayName={user?.displayName ?? intl('titles.guest')}

			/>
			<Box component="main" sx={{ flexGrow: 1, p: Style.matGridMultiplier * 2, mt: Style.matGridMultiplier * 8 }}>
				<Outlet />
			</Box>
		</Box>
	);
}

