
export const invalidKeyId = '-1';

// export interface IDocKey {
//     docId?: string;
// }

export interface IKey {
    // key: string;
    id: string;
    // familyMemberId?: string;
}

export interface IHash {
    [key: string]: any
}


export interface IDictionary<T> {
    [key: string]: T
}

export interface ISubHash<T> {
    default: T,
    Basic: T,
    Standard: T,
    Platnium: T,
    Lifetime: T
}

export interface IPagePosition {
    centerX: number;
    centerY: number;
    locationX: number;
    locationY: number;
    pageX: number;
    pageY: number;
}

export enum ColorStatus {
    critical,
    serious,
    caution,
    normal,
    standby,
    off,
}

export enum ErrorTypes {
    network,
}

export enum IconFamily {
    material = 'material',
    materialCommunity = 'material-community',
    simpleLineIcon = 'simple-line-icon',
    zocial = 'zocial',
    materialicons = 'materialicons',
    fontAwesome = 'font-awesome',
    feather = 'feather',
    octicon = 'octicon',
    ionicon = 'ionicon',
    foundation = 'foundation',
    evilicon = 'evilicon',
    entypo = 'entypo',
    antdesign = 'antdesign',
    fontAwesome5 = 'font-awesome-5',
    uri = 'uri',
    assetAlias = 'assetAlias',
    none = '',
}


export type IconType =
    //   | 'material'
    //   | 'material-community'
    //   | 'simple-line-icon'
    //   | 'zocial'
    | 'materialicons'
    | 'font-awesome'
    | 'feather'
    //   | 'octicon'
    | 'ionicon'
    //   | 'foundation'
    //   | 'evilicon'
    //   | 'entypo'
    //   | 'antdesign'
    //   | 'font-awesome-5'
    | string;


/*
 * fix for error: 
 *A non-serializable value was detected in an action, in the path: `payload.0.createdOn`. Value:, Object {
  "nanoseconds": 994000000,
  "seconds": 1638854756,
},
 */
export interface Timestamp {
    nanoseconds: number;
    seconds: number;
}

export interface ErrorObject {
    error: string;
    rawError: any;
}


export interface ConnectionError extends ErrorObject {
    hasConnectionIssues?: boolean;
    isTokenExpired?: boolean;
    idToken?: string | null;
}



export interface ImageURISource {
    /**
     * `uri` is a string representing the resource identifier for the image, which
     * could be an http address, a local file path, or the name of a static image
     * resource (which should be wrapped in the `require('./path/to/image.png')`
     * function).
     */
    uri?: string | undefined;
    /**
     * `bundle` is the iOS asset bundle which the image is included in. This
     * will default to [NSBundle mainBundle] if not set.
     * @platform ios
     */
    bundle?: string | undefined;
    /**
     * `method` is the HTTP Method to use. Defaults to GET if not specified.
     */
    method?: string | undefined;
    /**
     * `headers` is an object representing the HTTP headers to send along with the
     * request for a remote image.
     */
    headers?: { [key: string]: string } | undefined;
    /**
     * `cache` determines how the requests handles potentially cached
     * responses.
     *
     * - `default`: Use the native platforms default strategy. `useProtocolCachePolicy` on iOS.
     *
     * - `reload`: The data for the URL will be loaded from the originating source.
     * No existing cache data should be used to satisfy a URL load request.
     *
     * - `force-cache`: The existing cached data will be used to satisfy the request,
     * regardless of its age or expiration date. If there is no existing data in the cache
     * corresponding the request, the data is loaded from the originating source.
     *
     * - `only-if-cached`: The existing cache data will be used to satisfy a request, regardless of
     * its age or expiration date. If there is no existing data in the cache corresponding
     * to a URL load request, no attempt is made to load the data from the originating source,
     * and the load is considered to have failed.
     *
     * @platform ios
     */
    cache?: 'default' | 'reload' | 'force-cache' | 'only-if-cached' | undefined;
    /**
     * `body` is the HTTP body to send with the request. This must be a valid
     * UTF-8 string, and will be sent exactly as specified, with no
     * additional encoding (e.g. URL-escaping or base64) applied.
     */
    body?: string | undefined;
    /**
     * `width` and `height` can be specified if known at build time, in which case
     * these will be used to set the default `<Image/>` component dimensions.
     */
    width?: number | undefined;
    height?: number | undefined;
    /**
     * `scale` is used to indicate the scale factor of the image. Defaults to 1.0 if
     * unspecified, meaning that one image pixel equates to one display point / DIP.
     */
    scale?: number | undefined;
}

export type ImageRequireSource = number;

export type ImageSourcePropType = ImageURISource | ImageURISource[] | ImageRequireSource;
