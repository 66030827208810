import { RouteObject } from "react-router-dom";
import CardMembershipIcon from '@mui/icons-material/CardMembership';


//https://mui.com/material-ui/material-icons
//https://reactrouter.com/en/main/start/tutorial
type IRouteItem = RouteObject & {
    // path: string,
    title: string,
    iconElement?: React.ReactNode
}

export const routes: IRouteItem[] = [
    { path: "subscription", title: 'Manage Subscriptions', iconElement: <CardMembershipIcon /> },
    // { path: "/users", title: "users" },
    // { path: "/groups", title: "groups" },
    // { path: "/roles", title: "roles" },
    // { path: "/permissions-shemas", title: "Permissions Shemas" },
    // { path: "/properties", title: "Properties" }
];

export interface ITopNavItem {
    title: string,
    path: string,
}