import moment from 'moment';
import { Logger } from '../logger';

let logger = new Logger('DateUtil');

export enum TimeUnit { none, day, hour, minute, second }

export interface TimeLeftSummary {
    time: string | number;
    title: string;
    unit: TimeUnit
}
export interface CalculateTimeLeft {
    isDone: boolean;
    formattedTime: string;
    shortFormat: string;
    shortSummary: TimeLeftSummary;
    interval: number;
    totalMinutes: number;
}

// TODO: remove or update today property -> WeekRange
export interface DateRange {
    today: Date;
    startOfWeek: Date;
    endOfWeek: Date;
}

export interface WeekRange {
    startOfDay: Date;
    startOfWeek: Date;
    endOfWeek: Date;
}

export interface IHoursMinutes {
    hours: number;
    minutes: number;
}

export interface IDatePartItem extends IHoursMinutes {
    date: Date;
}

export interface IDatePart extends IDatePartItem {
    format: () => string;
    convert: () => IDatePartItem;
}


export const timeIntervals = {
    forHours: 1000 * 60 * 60,
    for30Minutes: 1000 * 60 * 30,
    for5Minutes: 1000 * 60 * 5,
    forMinutes: 1000 * 60,
    forSeconds: 1000
}


export const toUtcDate = (hours: number = 0, minutes: number = 0) => {
    var tempDate = new Date();
    var utcDate: Date = new Date(Date.UTC(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate(),
        hours, minutes, 0));

    // logger.debug(`toUtcDate -> hours: ${utcDate.getHours()}, minutes: ${utcDate.getMinutes()}`, utcDate);
    // logger.debug(`toUtcDate -> utc hours: ${utcDate.getUTCHours()}, utc minutes: ${utcDate.getUTCMinutes()}`, utcDate);

    return utcDate;
}

export const toUtcParts = (utcDate: Date, hours: number, minutes: number): IDatePart => {
    let data = {
        hours: hours,
        minutes: minutes,
        date: utcDate,
        format: () => {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        },
        convert: () => {
            return {
                hours: hours,
                minutes: minutes,
                date: utcDate,
            }
        }
    }

    // logger.debug(`toUtcParts`, data);
    return data;
}

export const toLocalDate = (dateNumber: number): string => {
    let date = new Date(dateNumber);
    let localDate = moment(date).local().format('LLLL');

    return localDate;
}

export const isFutureDate = (dateNumber: number): boolean => {
    let date = new Date(dateNumber);
    return moment(date).local().isAfter();
}


export const isInCurrentWeek = (date: Date): boolean => {
    let range = weekRange(new Date());

    return date >= range.startOfWeek && date <= range.endOfWeek;
}

export const weekRange = (date: Date): WeekRange => {
    let copyOf = startOfDay(date);

    // logger.debug(`dateUtil:weekRange -> before calling moment with date=${date}`)
    const dateTime = moment(date);
    const startOfWeek = dateTime.startOf('isoWeek').toDate();
    const endOfWeek = dateTime.endOf('isoWeek').toDate();


    //     moment().subtract(1, 'weeks').startOf('isoWeek')
    // moment().subtract(1, 'weeks').endOf('isoWeek')
    return {
        startOfWeek,
        endOfWeek,
        startOfDay: copyOf
    }
}

export const nextWeekRange = (): DateRange => {
    let today = startOfDay(new Date());

    // logger.debug(`dateUtil:nextWeekRange -> before calling moment with today=${today}`)

    let dateTime = moment(today).add(1, 'weeks').startOf('isoWeek');
    const startOfWeek = dateTime.startOf('isoWeek').toDate();
    const endOfWeek = dateTime.endOf('isoWeek').toDate();

    // logger.debug(`dateUtil:nextWeekRange -> startOfWeek=${startOfWeek.toString()}; endOfWeek=${endOfWeek.toString()}`);

    return {
        today: today,
        startOfWeek,
        endOfWeek,
    }
}


export const prevWeekRange = (date: Date): WeekRange => {
    // logger.debug(`dateUtil:nextWeekRange -> before calling moment with date=${date}`)
    const dateTime = moment(date);
    const startOfWeek = dateTime.subtract(1, 'weeks').startOf('isoWeek').toDate();
    const endOfWeek = dateTime.subtract(1, 'weeks').endOf('isoWeek').toDate();

    return {
        startOfWeek,
        endOfWeek,
        startOfDay: startOfWeek
    }
}

export const startOfDay = (date: Date | number) => {
    let copyOf = new Date(date.valueOf());
    copyOf.setMilliseconds(0);
    copyOf.setSeconds(0);
    copyOf.setMinutes(0);
    copyOf.setHours(0);
    return copyOf;
}

type weekCallback = (data: Date, index: number) => Promise<Date | void>;
type weekCallbackSync = (data: Date, index: number) => Date | void;

export const flattenWeekRange = async (week: WeekRange, cb?: weekCallback) => {
    let dates: Date[] = [];
    let max = 7;
    // let cnt = 0;
    const dateTime = startOfDay(week.startOfWeek)
    logger.debug(`DateUtil:flattenWeekRange:startOfDay -> week.startOfWeek`, week.startOfWeek);
    logger.debug(`DateUtil:flattenWeekRange -> dateTime`, dateTime.getDate());


    for (let index = 0; index < max; index++) {
        let date = new Date(dateTime.valueOf());
        date.setDate(dateTime.getDate() + index);

        // const date = dateTime.add(cnt, 'date').toDate();
        dates.push(date);
        logger.debug(`DateUtil:flattenWeekRange:for -> date=${date}`);

        if (cb) {
            await cb(date, index);
        }

        if (date.getDate() === week.endOfWeek.getDate()) {
            break;
        }

    }
    logger.debug(`DateUtil:flattenWeekRange:for -> dates`, dates);

    return dates;
}


export const flattenWeekRangeSync = (week: WeekRange, cb?: weekCallbackSync) => {
    let dates: Date[] = [];
    let max = 7;
    const dateTime = startOfDay(week.startOfWeek)


    for (let index = 0; index < max; index++) {
        let date = new Date(dateTime.valueOf());
        date.setDate(dateTime.getDate() + index);
        dates.push(date);

        if (cb) {
            cb(date, index);
        }

        if (date.getDate() === week.endOfWeek.getDate()) {
            break;
        }
    }
    return dates;
}

export const dateIsInWeek = function (date: Date) {
    // var firstDay = new Date(Date.UTC(date.getUTCFullYear(), 1)).getDay();
    // var totalDays = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDay())).getDate();
    // let week = Math.ceil((firstDay + totalDays) / 7);

    const numericDayInMonth = date.getDate();
    const dayInMonth = date.getDay();
    const weekOfMonth = Math.ceil((numericDayInMonth - 1 - dayInMonth) / 7);
    logger.log(weekOfMonth)

    let monthString = `${date.getUTCMonth()}-${date.getUTCFullYear()}`

    return {
        week: weekOfMonth,
        weekDateString: `${monthString}--w${weekOfMonth}`,
    }
}