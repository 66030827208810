// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
    getAuth,
} from "firebase/auth";
import {
    getFirestore,
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBlPSi3A8KrlJG17CSrMy6beKCFVXKrIsU",
    authDomain: "family-topia.firebaseapp.com",
    projectId: "family-topia",
    storageBucket: "family-topia.appspot.com",
    messagingSenderId: "870422927698",
    appId: "1:870422927698:web:3900a49682853c019bc28b",
    measurementId: "G-ZD6N86HBVH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export const Firebase = {
    app,
    analytics,
    auth,
    db,
}
