import axios from "axios";
import { ErrorObject, IHash } from "../../interfaces";
import { Logger } from "../../logger";
import { intl } from "../../translations";

let logger = new Logger('ApiService');

async function postForm(idToken: string | null | undefined, formData: IHash, endpoint: string): Promise<ErrorObject | any | null> {
    try {
        const response = await axios.post(endpoint, formData, {
            headers: {
                Authorization: `Bearer ${idToken}`,
                // 'Content-Type': 'multipart/form-data'
            },
        });

        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        logger.warn(`An error occurred while attempting to call ${endpoint}`, error);
        let errorObject: ErrorObject = {
            rawError: error,
            error: intl('error.generic.unknown')
        }

        return errorObject;
    }

    return null;
}


export const apiService = {
    postForm,
}