import { IHash } from "./common";

export type SubscriptionType = 'Basic'
    | 'Standard'
    | 'Platnium'
    | 'Lifetime'; // no one should have this except lyon

export type SubscriptionStatus = 'Pending' // Grace period
    | 'Active'
    | 'Platnium';

export type AccountStatus =
    'Pending' // Creation is pending
    | 'Active' // Account fully created with login profile
    | 'Disabled' // Cannot access account with a particular family
    | 'Canceled';

export type FamilyStatus = 'Pending' // invitation to join family sent
    | 'Rejected' // Invitation to join family rejected
    | 'Proxy' // Cannot create a login profile (no invitation sent)
    | 'Active' // Account fully created with login profile
    | 'Disabled' // Cannot access account with a particular family
    | 'Expired' // Invitation to join a family expired.
    | 'Canceled';

export enum FoodCategory {
    breakfast = 'Breakfast',
    lunch = 'Lunch',
    dinner = 'Dinner',
    beverage = 'Beverage',
    snack = 'Snack',
}

export enum DifficultyLevel {
    none = 'NA',
    beginner = 'Beginner',
    easy = 'Easy',// / Beginner / Novice.
    normal = 'Normal',/// Medium / Standard / Average / Intermediate.
    hard = 'Hard',// / Expert / Difficult.
    harder = 'Very hard',
}

export enum SubscriptionEnum {
    Basic = 'Basic',
    Standard = 'Standard',
    Platnium = 'Platnium',
    // Basic= 'Lifetime'
}

export enum StatusType {
    pending = 'Pending',
    assigned = 'Assigned',
    rejected = 'Rejected',
    proxy = 'Proxy',
    active = 'Active',
    disabled = 'Disabled',
    canceled = 'Canceled',
    expired = 'Expired',
    unknown = 'Unknown',
}

export enum WeekStatusType {
    none = 'none',
    readyForVoting = 'readyForVoting',
    votingStarted = 'votingStarted',
    votingClosed = 'votingClosed',
    weekStarted = 'weekStarted',
    endWeek = 'endWeek',

}

export enum VotingStatus {
    none = 'none',
    pending = 'pending',
    submitted = 'submitted',
    accept = 'accept',
    reject = 'reject',
}

export type Role = 'Owner' | 'Admin' | 'Relative' | 'Guest' | 'Nanny';

export enum Roles {
    owner = 'Owner',
    admin = 'Admin',
    member = 'Relative',
    guest = 'Guest',
    nanny = 'Nanny',
    none = 'None'
}

export enum AgeGroups {
    adult = 'Adult',
    gradeschooler = 'Gradeschooler',
    preschool = 'Preschool',
    teen = 'Teen',
    toddler = 'Toddler',
}

export const allAgeGroup = [
    AgeGroups.toddler,
    AgeGroups.preschool,
    AgeGroups.gradeschooler,
    AgeGroups.teen,
    AgeGroups.adult,
]

export const commonChoresAgeGroup = [
    AgeGroups.gradeschooler,
    AgeGroups.teen,
    AgeGroups.adult,
]


export const partialChoresAgeGroup = [
    AgeGroups.preschool,
    AgeGroups.gradeschooler,
    AgeGroups.teen,
    AgeGroups.adult,
]

export enum DateFrequency {
    daily = 'Daily',
    weekly = 'Weekly',
    monthly = 'Monthly',
    yearly = 'Yearly',
    custom = 'custom'
}

// export let intlAgeGroup = (ag: AgeGroups) => {
//     return ageGroupMap[ag] || ageGroupMap[AgeGroups.adult];
// }

// export let intlRole = (r: Roles) => {
//     return roleMap[r] || roleMap[Roles.guest];
// }

// export let intlStatus = (r: StatusType) => {
//     return statusMap[r] || statusMap[StatusType.pending];
// }

// export let intlFoodCategory = (f: FoodCategory | string) => {
//     return foodCategoryMap[f] || foodCategoryMap[FoodCategory.snack];
// }


// export let intlDifficultyLevel = (f: DifficultyLevel | string) => {
//     return difficultyLevelMap[f] || difficultyLevelMap[DifficultyLevel.none];
// }

// export let ageGroupMap = {
//     'Toddler': intl('age.group.toddler'),
//     'Preschool': intl('age.group.preschool'),
//     'Gradeschooler': intl('age.group.grade.schooler'),
//     'Teen': intl('age.group.teen'),
//     'Adult': intl('age.group.adult'),
// }

// export let foodCategoryMap: IHash = {
//     'Beverage': intl('food.category.beverage'),
//     'Breakfast': intl('food.category.breakfast'),
//     'Dinner': intl('food.category.dinner'),
//     'Lunch': intl('food.category.lunch'),
//     'Snack': intl('food.category.snack'),
// }

export let foodCategoryStartTime: IHash = {
    'Beverage': NaN,
    'Breakfast': 9,
    'Dinner': 6,
    'Lunch': 12,
    'Snack': NaN,
}

// export let dayBreakdown: IHash = {
//     'Night Snack': {startHour: 9, startMin: 0, endHour: 3, endMin: 0},
//     'Early morning Snack': {startHour: 3, startMin: 0, endHour: 5, endMin: 0},
//     'Breakfast': {startHour: 5, startMin: 0, endHour: 11, endMin: 0},
//     'Morning Snack': {startHour: 11, startMin: 0, endHour: 12, endMin: 0},
//     'Lunch': {startHour: 12, startMin: 0, endHour: 2, endMin: 0},
//     'Snack': {startHour: 2, startMin: 0, endHour: 4, endMin: 0},
//     'Dinner': {startHour: 4, startMin: 0, endHour: 9, endMin: 0},
// }

export let ageGroupToEnum: IHash = {
    'Toddler': AgeGroups.toddler,
    'Preschool': AgeGroups.preschool,
    'Gradeschooler': AgeGroups.gradeschooler,
    'Teen': AgeGroups.teen,
    'Adult': AgeGroups.adult,
}

// export let difficultyLevelMap: IHash = {
//     'NA': intl('difficulty.level.category.none'),
//     'Beginner': intl('difficulty.level.category.beginner'),
//     'Easy': intl('difficulty.level.category.easy'),
//     'Normal': intl('difficulty.level.category.normal'),
//     'Hard': intl('difficulty.level.category.hard'),
//     'Very hard': intl('difficulty.level.category.harder'),
// }


export let difficultyLevelNumericMap: IHash = {
    0: DifficultyLevel.none,
    1: DifficultyLevel.beginner,
    2: DifficultyLevel.easy,
    3: DifficultyLevel.normal,
    4: DifficultyLevel.hard,
    5: DifficultyLevel.harder,
}

let toNumericDifficultyLevelMap: IHash = {};
toNumericDifficultyLevelMap[DifficultyLevel.none] = 0;
toNumericDifficultyLevelMap[DifficultyLevel.beginner] = 1;
toNumericDifficultyLevelMap[DifficultyLevel.easy] = 2;
toNumericDifficultyLevelMap[DifficultyLevel.normal] = 3;
toNumericDifficultyLevelMap[DifficultyLevel.hard] = 4;
toNumericDifficultyLevelMap[DifficultyLevel.harder] = 5;

export const toNumericDifficultyLevel = (level: DifficultyLevel | string): number => {
    return toNumericDifficultyLevelMap[level] ?? 0;
}


// export const toDifficultyLevel = (level?: number): string => {
//     level = level ?? 0;
//     let num = round(level);
//     let key = difficultyLevelNumericMap[num];
//     return difficultyLevelMap[key] ?? difficultyLevelMap[DifficultyLevel.none];
// }
// difficultyLevel


