import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AuthForm, AuthLogout } from '../../components';
import { intl } from '../../translations';
import { useAuthContext } from '../../providers';
import { Logger } from '../../logger';

let logger = new Logger(AuthPage.name);
export function AuthPage() {

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [shouldShowLogin, setShouldShowLogin] = useState(true);

    //------------------------------------------------
    // Hook
    //------------------------------------------------
    let { user } = useAuthContext();
    logger.debug(`AuthPage:body -> user=`, user);

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        setShouldShowLogin(user == null);
    }, [user])

    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        <Box>
            {shouldShowLogin ? <AuthForm
                title={intl('titles.login')}
            /> : <AuthLogout />}
        </Box>
    );
}