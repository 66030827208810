import * as React from 'react';
import Button from '@mui/material/Button';
import { onActionFunc } from '../../../misc';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import { intl } from '../../../translations';
import { Stack } from '@mui/system';
import { LoginButtonType } from '../../../interfaces';
import { Style } from '../../../styling';
import './auth-button.scss';
import googleLogo from '../../../assets/google__G__Logo.svg';


interface OptionsProps {
    title: string,
    handleAction: onActionFunc<LoginButtonType>,
}
const buttonStyle: React.CSSProperties = {
    color: 'white',
    border: "0.5px solid black",
    textTransform: 'none',
    width: '140pt',
}

const appleStyle: React.CSSProperties = {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: 'white',
    marginRight: Style.matGrid * 2
};
const appleButtonStyle: React.CSSProperties = Object.assign({}, buttonStyle, {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
});

const facebookStyle: React.CSSProperties = {
    backgroundColor: 'rgb(25, 118, 210)',
    color: 'white',
    marginRight: Style.matGrid * 2
};
const facebookButtonStyle: React.CSSProperties = Object.assign({}, buttonStyle, {
    backgroundColor: 'rgb(25, 118, 210)',
    width: '152pt'
});


const googleStyle: React.CSSProperties = {
    backgroundColor: '#dd4b39',
    color: 'white',
    marginRight: Style.matGrid * 2
};
const googleButtonStyle: React.CSSProperties = Object.assign({}, buttonStyle, {
    backgroundColor: '#dd4b39',
});


export function AuthButton(props: OptionsProps) {
    const { handleAction } = props;
    return (
        <Stack
            direction="row"
            spacing={2}>
            <Button
                onClick={() => handleAction('apple')}
                style={appleButtonStyle}
            >
                <AppleIcon fontSize='medium' style={appleStyle} />

                {intl('buttons.common.apple')}
            </Button>
            <Button
                onClick={() => handleAction('facebook')}
                style={facebookButtonStyle}
            >
                <FacebookIcon fontSize='medium' style={facebookStyle} />
                {intl('buttons.common.facebook')}
            </Button>

            <div
                className="google-btn"
                role='button'
                tabIndex={0}
                onClick={() => handleAction('google')} >
                <div className="google-icon-wrapper">
                    <img className="google-icon" src={googleLogo} />
                </div>
                <p className="btn-text"><b>{intl('buttons.common.google')}</b></p>
            </div>

        </Stack>
    );
}