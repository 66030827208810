import { OAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { signInWith } from "./helpers";

const provider = new OAuthProvider('apple.com');
provider.addScope('email');
provider.addScope('name');

//https://firebase.google.com/docs/auth/web/apple 
//https://medium.com/@ertemishakk/sign-in-with-apple-using-react-and-nodejs-b3a19671184d
export function useAppleAuthentication() {
    async function signInWithApple() {
        return signInWith(provider, (result) => OAuthProvider.credentialFromResult(result));
    }

    return [signInWithApple] as const;
}

