import * as React from 'react';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthButton } from './auth-button';
import { LoginButtonType } from '../../../interfaces';
import { Backdrop, CircularProgress } from '@mui/material';
import { BackgroundLogo } from '../../../assets';
import { useAppleAuthentication, useFacebookAuthentication, useGoogleAuthentication } from '../../../hooks';
import { useEffect } from 'react';
import { subscribe } from '../../../utils';
import { intl } from '../../../translations';
import { useNavigate } from 'react-router-dom';

interface OptionsProps {
    title: string,
}

export function AuthForm(props: OptionsProps) {
    const { title } = props;
    const [signInWithApple] = useAppleAuthentication();
    const [signInWithFacebook] = useFacebookAuthentication();
    const [signInWithGoogle] = useGoogleAuthentication();

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [isLoading, setIsLoading] = React.useState(false);
    const [actionTriggered, setActionTriggered] = React.useState<LoginButtonType | null>(null);

    //------------------------------------------------
    // Hook
    //------------------------------------------------
    let navigate = useNavigate();

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    useEffect(() => {
        if (!actionTriggered) return;

        return subscribe(async () => {
            let result;
            switch (actionTriggered) {
                case 'apple':
                    result = await signInWithApple();
                    break;

                case 'facebook':
                    result = await signInWithFacebook();
                    break;

                default:
                    result = await signInWithGoogle();
                    break;
            }
            return result;
        }, async (result) => {
            setIsLoading(false);
            setActionTriggered(null);

            if (!result || !result.isSuccessful) {
                toast.error(result?.displayMessage ?? intl('error.generic.login'));
            } else {
                navigate('/subscription')
            }
        });
    }, [actionTriggered])

    //------------------------------------------------
    // Handlers
    //------------------------------------------------
    const handleAction = (type: LoginButtonType) => {
        // toast.error('Please check the Email');
        setIsLoading(true);
        setActionTriggered(type);
    }

    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box className="heading-container">
                <h3>
                    {title}
                </h3>
            </Box>
            <Box
                // component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
            // noValidate
            // autoComplete="off"
            >
                {/* <TextField
                    id="email"
                    label="Enter the Email"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)} />
                <TextField
                    id="password"
                    label="Enter the Password"
                    variant="outlined"
                    onChange={(e) => setPassword(e.target.value)} /> */}
                <BackgroundLogo></BackgroundLogo>
            </Box>
            <AuthButton title={title} handleAction={handleAction} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />

        </Box>
    );
}