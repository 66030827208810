import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Logo } from '../../../assets';
import { Style, theme } from '../../../styling';
import { useAuthContext } from '../../../providers';
import { isBlank, isNotBlank, subscribe } from '../../../utils';
import { useScrollTrigger } from '@mui/material';
import featureGraphics from '../../../assets/feature_graphics_1024_500.png';
import { intl } from '../../../translations';
import { ITopNavItem } from '../../../navigation';
import { Firebase } from '../../../config';
import PersonIcon from '@mui/icons-material/Person';



interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactElement;
}

export function ElevationScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children ?? <></>, {
        elevation: trigger ? 4 : 0,
    });
}

export function GeneralAppBar() {
    const topNavRoutes: ITopNavItem[] = [
        { title: intl('menu.subscription'), path: 'subscription' },
        // { title: intl('menu.logout'), path: '/login' },
    ];
    const pages: string[] = [];

    //------------------------------------------------
    // Hook
    //------------------------------------------------
    let { user } = useAuthContext();

    //------------------------------------------------
    // States
    //------------------------------------------------
    const [isLoading, setIsLoading] = React.useState(false);
    const [auth, setAuth] = React.useState(false);
    const [actionTriggered, setActionTriggered] = React.useState<boolean>(false);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


    // const pages: string[] = [user?.displayName ?? 'd'];

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    React.useEffect(() => {
        setAuth(isNotBlank(user?.uid));
    }, [user?.uid]);

    //------------------------------------------------
    // Effect
    //------------------------------------------------
    React.useEffect(() => {
        if (!actionTriggered) return;

        return subscribe(async () => {
            await Firebase.auth.signOut();
            return true;
        }, async (_result) => {
            setIsLoading(false);
            setActionTriggered(false);
        });
    }, [actionTriggered])


    //------------------------------------------------
    // Handlers
    //------------------------------------------------
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLogout = () => {
        setAnchorElNav(null);
        setIsLoading(true);
        setActionTriggered(true);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        // <ElevationScroll>

        <AppBar >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            backgroundColor: '#ffffff',
                            borderRadius: Style.matGridType * 12,
                            p: Style.matGridMultiplier * 0.5,
                            textDecoration: 'none',
                        }}
                    >
                        <Logo height={Style.matGridType * 11} width={Style.matGridType * 11} marginLeft={0} type='hand' />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        FamilyTopia
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <span style={{ color: '#ffffff' }}>FamilyTopia</span>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar>
                                    {isBlank(user?.photoURL) && <PersonIcon />}
                                    {isNotBlank(user?.photoURL) && <img
                                        alt={'Your profile'}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                        }}
                                        src={user?.photoURL ?? ''} />}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {auth && topNavRoutes.map((settings) => (
                                <MenuItem key={settings.path} component="a" href={settings.path} >
                                    <Typography textAlign="center">{settings.title}</Typography>
                                </MenuItem>
                            ))}
                            {auth && <MenuItem onClick={handleLogout} >
                                <Typography textAlign="center">{intl('menu.logout')}</Typography>
                            </MenuItem>}
                            {!auth && <MenuItem key={intl('titles.login')} component="a" href={'/login'} >
                                <Typography textAlign="center">{intl('titles.login')}</Typography>
                            </MenuItem>}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        // </ElevationScroll>
    );
}

export let TopBanner = () => {
    return (
        <Box sx={{
            alignSelf: 'stretch',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
        }}>
            <img src={featureGraphics} style={{
                alignSelf: 'center',
                objectFit: 'fill',
                backgroundRepeat: 'no-repeat',
                // marginBottom: Style.matGridMultiplier,
                // height: '40vmin',
                width: '100%',

            }} alt="logo" />
        </Box>
    )
}
