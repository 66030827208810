import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { GeneralAppBar, TopBanner } from './top-banner';
import { CssBaseline, Fab, Fade, useScrollTrigger } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AppDescription } from './app-description';
import { Footer } from '../../atom';


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ScrollTop(props: Props) {
    const { window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const anchor = (
            (event.target as HTMLButtonElement)?.ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Fab size="small" aria-label="scroll back to top"
                    onClick={handleClick}>
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Fade>
    );
}


export function Content() {
    //------------------------------------------------
    // Renderers
    //------------------------------------------------

    return (
        <React.Fragment>
            <CssBaseline />
            <GeneralAppBar />
            <div style={{ flex: 1 }}>
                <Toolbar id="back-to-top-anchor" />
                <TopBanner />
                <Container sx={{
                    m: 0,
                    p: 0,
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                }}>
                    <Container>
                        <AppDescription />
                    </Container>
                </Container>
                <Footer></Footer>
            </div>
            <ScrollTop />

        </React.Fragment>
    );
}
