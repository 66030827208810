import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { intl } from '../../../translations';
import { Style } from '../../../styling';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';


export function AppDescription() {
    const bullets = [
        intl('pages.home.second.paragraph.bullet.points.invitation'),
        intl('pages.home.second.paragraph.bullet.points.bonding'),
        intl('pages.home.second.paragraph.bullet.points.event'),
    ];

    return (
        <React.Fragment>
            <Typography variant="h2" noWrap component="h1" sx={{
                mt: Style.matGridMultiplier * 2,
            }}>
                {intl('titles.home')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    mt: Style.matGridMultiplier * 2,
                }}>
                <Box
                    sx={{
                        display: 'inline-block',
                        flex: 1,
                    }}>
                    <Typography variant="body1" color="text.secondary" align="justify">
                        {intl('description.home.paragraph1')}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" align="justify">
                        {intl('description.home.paragraph2')}
                    </Typography>
                    <List dense={true}>
                        {bullets.map(x =>
                            <ListItem>
                                <ListItemIcon>
                                    <DoubleArrowIcon />
                                </ListItemIcon>
                                <ListItemText primary={x} >{x}</ListItemText>
                            </ListItem>)}

                    </List>
                    <Typography variant="body1" color="text.secondary" align="justify" sx={{
                        mb: Style.matGridMultiplier * 2
                    }}>
                        {intl('description.home.paragraph3')}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}
