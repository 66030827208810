import { FacebookAuthProvider } from "firebase/auth";
import { signInWith } from "./helpers";


//https://firebase.google.com/docs/auth/web/facebook-login
const provider = new FacebookAuthProvider();
export function useFacebookAuthentication() {
    async function signInWithFacebook() {
        return signInWith(provider, (result) => FacebookAuthProvider.credentialFromResult(result));
    }

    return [signInWithFacebook] as const;
}

