import i18n from "i18next";
import { Paper, Typography } from "@mui/material";
import { SubscriptionDto } from "../../../model-interfaces";
import { intl } from "../../../translations";
import { isFutureDate, isNotBlank, toLocalDate } from "../../../utils";


interface OptionsProp {
    subscription: SubscriptionDto;
}

export const SubscriptionDetails = ({ subscription }: OptionsProp) => (
    <section aria-label={intl('ariaLabels.pages.subscription.details')}>
        <Typography variant="h1" gutterBottom sx={{
            fontSize: '3rem'
        }}>
            {intl('titles.subscription')}
        </Typography>
        <Paper variant="outlined" sx={{ p: 1 }} >
            <table>
                <tbody>
                    <tr>
                        <th> {intl('pages.subscription.type')}</th>
                        <td>{subscription.subscriptionType}</td>
                    </tr>
                    <tr>
                        <th> {intl('pages.subscription.status')}</th>
                        <td>{subscription.status}</td>
                    </tr>
                    {isNotBlank(subscription.daysUntilDue?.toString()) && subscription.daysUntilDue > -1 ? <tr>
                        <th> {intl('pages.subscription.daysUntilNext')}</th>
                        <td>{subscription.daysUntilDue}</td>
                    </tr> : null}
                    {isNotBlank(subscription.cancelledAt?.toString()) && subscription.cancelledAt > -1 ?
                        <tr>
                            <th> {intl('pages.subscription.cancelled')}</th>
                            {isFutureDate(subscription.cancelledAt) ?
                                <td> {i18n.t('pages.subscription.cancelledStatusFuture', { cancelled: toLocalDate(subscription.cancelledAt) })}</td>
                                : <td> {i18n.t('pages.subscription.cancelledStatus', { cancelled: toLocalDate(subscription.cancelledAt) })}</td>}
                        </tr> : null
                    }
                </tbody>
            </table>
        </Paper>
    </section>
);
