import React, { useState, createContext, useContext, useEffect } from 'react';
import { Box, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { User } from 'firebase/auth';

import { Firebase } from '../config';
import { autoHideDurationSnackBar } from '../constants';
import { Profile } from '../hooks/firebase/models';
import { isError } from '../hooks/firebase/type-guards';
import { ConnectionError } from '../interfaces';
import { loginService } from '../services';
import { intl } from '../translations';
import { isBlank, isConnectionError, isNotBlank, subscribe } from '../utils';
import { BackgroundLogo } from '../assets';
import { authenticate } from '../hooks/auth/helpers';
import { Logger } from '../logger';


export interface AuthenticatedUserProps {
    children?: any;
    /**
     * Used to locate this button in end-to-end tests.
     */
    testID?: string;
}

export interface UserState {
    userIsLoggingIn: boolean;
    isProfileLoaded: boolean;
    profile: Profile | null;
    user: User | null;
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    setRefreshData: React.Dispatch<React.SetStateAction<number>>;
    setUserIsLoggingIn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<UserState>({
    isProfileLoaded: false,
    userIsLoggingIn: false,
    profile: null,
    user: null,
    token: null,
    setUser: () => { },
    setToken: () => { },
    setRefreshData: () => { },
    setUserIsLoggingIn: () => { }
});
export const useAuthContext = () => useContext(AuthContext);

let logger = new Logger(AuthContainer.name);

export function AuthContainer(props: AuthenticatedUserProps) {
    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);
    const [initializingData, setInitializingData] = useState(true);

    const [errorMessage, setErrorMessage] = useState('');
    const [user, setUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [refreshData, setRefreshData] = useState<number>(0);
    const [profile, setProfile] = useState<Profile | null>(null);
    const [isProfileLoaded, setIsProfileLoaded] = useState<boolean>(false);
    const [userIsLoggingIn, setUserIsLoggingIn] = useState<boolean>(false);
    // const logo = cacheImages().logo;
    // const baseStyles = useStyling();

    let { children } = props;
    logger.log(`AuthContainer:body -> user=`, user);

    //------------------------------------------------
    // Hooks
    //------------------------------------------------
    useEffect(() => {
        // onAuthStateChanged returns an unsubscriber
        logger.debug(`AuthContainer:useEffect:Triggering initialization and loading of firestore`);
        const unsubscribeAuth = Firebase.auth.onAuthStateChanged(async (authenticatedUser: React.SetStateAction<User | null>) => {
            if (userIsLoggingIn) {
                logger.debug(`AuthContainer:useEffect:onAuthStateChanged -> Skipping assigning user data; still finailizing the login process;`);
                return;
            }

            try {
                if (authenticatedUser) {
                    setUser(authenticatedUser);
                } else {
                    if (user != null) { setUser(null); }
                }
                // logger.debug('User loaded');
            } catch (error) {
                logger.debug(error);
                logger.warn(error);
            }

            if (initializing) {
                logger.debug(`AuthContainer:useEffect:onAuthStateChanged -> initialization completed`);
                setInitializing(false);
            }
        }, (firebaseError: any) => {
            logger.debug('Yep, Auth error', firebaseError);
            logger.warn(firebaseError);
        });
        // unsubscribe auth listener on unmount
        return unsubscribeAuth;
    }, []);

    useEffect(() => {
        if (initializing) return;
        if (!user || isBlank(user?.uid)) {
            //user is not logged in.. skipping preloading data.
            setInitializingData(false);
            return;
        }
        logger.debug(`AuthContext:useEffect:seeding -> before calling seedData.init. uid=${user?.uid}`);

        return subscribe(async () => {
            let isTokenValid = true;
            let idToken = null;
            let tokenObj: ConnectionError = {
                error: '',
                rawError: {},
                idToken,
            };
            try {
                logger.debug(`AuthContext:useEffect:getIdToken -> before calling user.getIdToken(true) uid=${user?.uid}`);
                idToken = await user.getIdToken(true);
                tokenObj.idToken = idToken;

                logger.debug(`AuthContext:useEffect:getIdToken -> after calling user.getIdToken(true). if here, this user is still authorised.`, idToken);
                await authenticate(idToken);
                logger.debug(`AuthContext:useEffect:authenticate -> after calling authenticate(idToken)`);
            } catch (error: any) {
                tokenObj.rawError = error
                if (error.code === 'auth/user-token-expired') {
                    tokenObj.isTokenExpired = true;
                    // token invalidated. No action required as onAuthStateChanged will be fired again with null
                    logger.warn('token invalidated. No action required as onAuthStateChanged will be fired again with null:' + error.code, error);
                    //TODO: user need to relogin
                } else {
                    logger.warn('Unexpected error: ' + error.code, error);
                    tokenObj.error = intl('message.error.network.connection');
                    tokenObj.hasConnectionIssues = true;
                }
                isTokenValid = false;
            }

            if (!isTokenValid) return tokenObj;
            logger.debug(`AuthContext:useEffect:seeding -> before calling seedData.init. uid=${user?.uid}`);
            try {
                logger.debug(`AuthContext:useEffect:seeding -> after calling seedData.init(). uid=${user?.uid}`);
                logger.debug(`AuthContext:useEffect:seeding -> before calling loginService.getOCreateProfile(). uid=${user?.uid}`);
                // init user profile 

                let profile = await loginService.getOCreateProfile(user);
                logger.debug(`AuthContext:useEffect:seeding -> after calling loginService.getOCreateProfile(). uid=${user?.uid}`);
                let profileCreated = !!profile;

                return {
                    profile,
                    profileCreated,
                    idToken,
                }
            } catch (error) {
                logger.debug(`AuthContext:useEffect:seeding -> encountered an error. uid=${user?.uid}`, error);

                let obj: ConnectionError = {
                    error: intl('error.default'),
                    rawError: error,
                    idToken,
                };
                if (isConnectionError(error)) {
                    obj.error = intl('message.error.network.connection');
                    obj.hasConnectionIssues = true;
                }
                return obj;
            }
        }, async (result) => {
            if (result && !isError(result)) {
                setProfile(result.profile);
                setIsProfileLoaded(result.profileCreated);
                setToken(result.idToken);
                setInitializingData(false);
            } else {
                setIsProfileLoaded(false);

                if (result?.hasConnectionIssues) {
                    setToken(result?.idToken ?? null);
                    setErrorMessage(result.error);
                    // toast.error(result.error);
                }

                if (isBlank(result?.error) || result?.isTokenExpired) {
                    setInitializingData(false);
                    setToken(null);
                } else {
                    setToken(result?.idToken ?? null);
                }
            }
        })
    }, [user?.uid, refreshData, initializing]);


    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage('');
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={(e) => {
                setRefreshData(refreshData + 1);
                handleClose(e);
            }}>
                {intl('buttons.common.retry')}
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    let showErrorMessage = () => {
        if (isBlank(errorMessage)) return <></>;

        return (
            <Snackbar
                open={isNotBlank(errorMessage)}
                autoHideDuration={autoHideDurationSnackBar}
                onClose={handleClose}
                message={errorMessage}
                action={action}
            />
        );
    }

    if (initializing || initializingData) {
        return (
            <Box sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <BackgroundLogo />
                {showErrorMessage()}
            </Box>
        );
    }

    logger.debug(`--------------------------------------------------------------------------------------------------------`);
    logger.debug(`* Authentication initialization completed - Results                                                                                     `);
    logger.debug(`* uid=${user?.uid}                                                                                     `);
    logger.debug(`* profile=${profile?.docId}                                                                    `);
    logger.debug(`* initializing=${initializing}                                                                         `);
    logger.debug(`* initializingData=${initializingData}                                                                 `);
    logger.debug(`* isProfileLoaded=${isProfileLoaded}                                                                    `);
    logger.debug(`* userIsLoggedIn=${userIsLoggingIn}                                                                    `);
    logger.debug(`--------------------------------------------------------------------------------------------------------`);

    // return (
    //     <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    //         <Text>Test</Text>
    //     </View>
    // );
    return (
        <AuthContext.Provider value={{
            isProfileLoaded,
            userIsLoggingIn,
            profile,
            user,
            token,
            setUser,
            setToken,
            setRefreshData,
            setUserIsLoggingIn
        }}>
            {children}
            {showErrorMessage()}
        </AuthContext.Provider>
    );
};
