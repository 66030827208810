import { SubscriptionEnum, SubscriptionType } from "../interfaces";

export interface PriceDto {
    subscriptionType: SubscriptionType;
    monthlyPriceId: string;
    yearlyPriceId: string;
}

export const priceDto: PriceDto = {
    subscriptionType: SubscriptionEnum.Basic,
    monthlyPriceId: '',
    yearlyPriceId: ''
}