import i18n from "i18next";
import { Box, Button, Link, Paper, Alert } from "@mui/material";
import { SubscriptionDto } from "../../../model-interfaces";
import { intl } from "../../../translations";
import { ErrorObject, SubscriptionEnum, SubscriptionType } from "../../../interfaces";
import { isBlank, isNotBlank, onActionFunc } from "../../../utils";
import { Endpoints } from "../../../constants";
import { PriceDto } from "../../../model-interfaces/price-dto";
import { FormBox } from "../../atom";
import { Style } from "../../../styling";
import { Logger } from "../../../logger";


interface OptionsProp {
    customerPortalUrl: string;
    subscription: SubscriptionDto;
    standardPrice: PriceDto;
    platniumPrice: PriceDto;
    onError: onActionFunc<ErrorObject>;
    token: string | null;
}

interface PurchaseOptionsProp {
    sessionId: string;
    message: string;
    purchasePlan: SubscriptionType;
    token: string | null;
    onError: onActionFunc<ErrorObject>;
}
let logger = new Logger(SubscriptionPlanPurchased.name);

export const SubscriptionPlans = ({ customerPortalUrl, subscription, platniumPrice, standardPrice, token, onError }: OptionsProp) => (
    <Box
        component="section"
        aria-label={intl('ariaLabels.pages.subscription.plans')}>
        {subscription.subscriptionType === SubscriptionEnum.Basic ? <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                mt: 2,
                '& > :not(style)': {
                    p: 1,
                    m: 1,
                    width: 128,
                    height: 128,
                },
            }}>
            <Paper variant="outlined">
                <FormBox
                    allowRedirect
                    action={Endpoints.subscription}
                    token={token}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    onError={onError}
                >
                    <h4>{intl('pages.subscription.standard')}</h4>
                    <input type="hidden" name="lookup_key" value={standardPrice.monthlyPriceId} />
                    <Button variant="outlined" type="submit">{intl('pages.subscription.upgrade')}</Button>
                </FormBox>
            </Paper>

            <Paper variant="outlined">
                <FormBox
                    allowRedirect
                    action={Endpoints.subscription}
                    token={token}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    onError={onError}>
                    <h4>{intl('pages.subscription.platnium')}</h4>
                    <input type="hidden" name="lookup_key" value={platniumPrice.monthlyPriceId} />
                    <Button variant="outlined" type="submit">{intl('pages.subscription.upgrade')}</Button>
                </FormBox>
            </Paper>
        </Box> :
            <Link
                href={customerPortalUrl}
                underline="hover"
                sx={{
                    mt: Style.matGridMultiplier * 2,
                    display: 'flex'
                }}>
                {intl('pages.subscription.manage')}
            </Link>}
    </Box>
);

export function SubscriptionPlanPurchased({ sessionId, purchasePlan, message, token, onError }: PurchaseOptionsProp) {
    if (isBlank(message) && isBlank(sessionId)) return null;
    logger.debug(`SubscriptionPlanPurchased: token=${token}`);
    return (
        <section>
            {isNotBlank(message) ? <Alert
                severity="warning"
                sx={{
                    mb: Style.matGridMultiplier * 2
                }}>{message}</Alert> : null}
            {(isNotBlank(sessionId) && isBlank(message)) ? <Alert
                severity="success"
                sx={{
                    mb: Style.matGridMultiplier * 2
                }}>{i18n.t('pages.subscription.purchase', { plan: purchasePlan })}</Alert> : null}
            {isNotBlank(sessionId) ? <FormBox
                allowRedirect
                action={Endpoints.customerPortal}
                token={token}
                onError={onError}
                sx={{
                    mb: Style.matGridMultiplier * 2
                }}>
                <input
                    type="hidden"
                    name="session_id"
                    value={sessionId}
                />
                <Button variant="outlined" type="submit">{intl('pages.subscription.manage')}</Button>
            </FormBox> : null}
        </section>
    );
};
