import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Style } from '../styling';
import { Content } from '../components';


export function HomePage() {
    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        <Box sx={{ display: 'flex' }} className="App">
            <Content></Content>
            {/* <Box component="main" sx={{ flexGrow: 1, p: Style.matGridMultiplier * 2, mt: Style.matGridMultiplier * 8 }}>
            </Box> */}
        </Box>
    );
}