import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { customTheme } from './custom-theme';

// A custom theme for this app
export const theme = createTheme({
    palette: {
        primary: {
            main: customTheme.colors.primary,
            light: customTheme.colors.primaryLight,
            dark: customTheme.colors.primaryDark,
        },
        secondary: {
            main: customTheme.colors.accent,
            light: customTheme.colors.accentLight,
            dark: customTheme.colors.accentDark,
        },
        error: {
            main: red.A400,
        },
    },
});
