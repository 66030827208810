
import { enumToBitValues, INamePair, ISelected } from "./types";

//------------------------------------------------
// Types & Interfaces
//------------------------------------------------
export const SELECT_ALL_DAYS = 127;
export interface IDayKey extends INamePair, ISelected {
    min: string;
}

export enum Days {
    Monday = 1 << 0, // 1
    Tuesday = 1 << 1, // 2
    Wednesday = 1 << 2, // 4
    Thursday = 1 << 3, // 8
    Friday = 1 << 4, // 16
    Saturday = 1 << 5, // 32
    Sunday = 1 << 6, // 64
    // All = ~(~0 << 7),
}

export const weekdays: IDayKey[] = [
    { abbr: "Sun", name: 'Sunday', min: 'S', isSelected: false },
    { abbr: "Mon", name: 'Monday', min: 'M', isSelected: false },
    { abbr: "Tue", name: 'Tuesday', min: 'T', isSelected: false },
    { abbr: "Wed", name: 'Wednesday', min: 'W', isSelected: false },
    { abbr: "Thur", name: 'Thursday', min: 'T', isSelected: false },
    { abbr: "Fri", name: 'Friday', min: 'F', isSelected: false },
    { abbr: "Sat", name: 'Saturday', min: 'S', isSelected: false },
    // weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    // weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
];

// export const days = [
//     'Sunday',
//     'Mon',
//     'Tue',
//     'Wed',
//     'Thu',
//     'Fri',
//     'Sat'
// ];

export const getWeekDayAt = (dayIndex: number, days: IDayKey[]) => {
    return days[dayIndex];
}

export const months: INamePair[] = [
    { abbr: "Jan", name: "January" },
    { abbr: "Feb", name: "February" },
    { abbr: "Mar", name: "March" },
    { abbr: "Apr", name: "April" },
    { abbr: "May", name: "May" },
    { abbr: "Jun", name: "June" },
    { abbr: "Jul", name: "July" },
    { abbr: "Aug", name: "August" },
    { abbr: "Sep", name: "September" },
    { abbr: "Oct", name: "October" },
    { abbr: "Nov", name: "November" },
    { abbr: "Dec", name: "December" }
];

//------------------------------------------------
// Functions
//------------------------------------------------
const daysBits = enumToBitValues(Days);
/*
const selectedDay = toSelectedDays( 5)
output: [
  true,   //  1 & 5
  false,  //  2 & 5
  true,   //  4 & 5
  false,  //  8 & 5
  false,  // 16 & 5
  false,  // 32 & 5
  false,  // 64 & 5
]
*/
export function toSelectedDays(bit: number): IDayKey[] {
    return daysBits.map((b: number, index: number) => toDayKey((bit & b) === b, index))
}

/*
const selectedDay = toDayBits( [
  true,
  false,
  true,
  false,
  false,
  false,
  false,
])

 output: 5
*/
export function toDayBits(days: IDayKey[]) {
    let reducer = (total: number, day: IDayKey, i: number) => total + (day.isSelected ? daysBits[i] : 0);
    return days.reduce(reducer, 0)
}

function toDayKey(isSelected: boolean, index: number): IDayKey {
    let day = Object.assign({}, weekdays[index]);
    day.isSelected = isSelected;
    return day;
}



