import { Logger } from "../../logger";
import { startOfDay, weekRange } from "../../utils";

const familyName = 'family';
const profileName = 'profile';
let logger = new Logger('CollectionPath');

export const commonPaths = {
    chores: 'common/shared/chores',
    activities: 'common/shared/activities',
    meals: 'common/shared/meals',
    testConnections: 'common',
}

export const familyPaths = {
    family: `${familyName}`,
    // familyBy:  (familyId: string) => `${familyName}/${familyId}`,
    chores: (familyId: string) => `${familyName}/${familyId}/chores`,
    appSettings: (familyId: string) => `${familyName}/${familyId}/appSettings`,
    activities: (familyId: string) => `${familyName}/${familyId}/activities`,
    meals: (familyId: string) => `${familyName}/${familyId}/meals`,
    members: (familyId: string) => `${familyName}/${familyId}/members`,
    weeklyStats: (familyId: string, date: Date) => `${familyName}/${familyId}/stats/${toPathFromDate(date)}/weekly`,
}

export const itemRequestsPaths = {
    chores: (familyId: string, date: string) => `${familyName}/${familyId}/itemRequests/${date}/chores`,
    activities: (familyId: string, date: string) => `${familyName}/${familyId}/itemRequests/${date}/activities`,
    meals: (familyId: string, date: string) => `${familyName}/${familyId}/itemRequests/${date}/meals`,
}
export const milestonesPaths = {
    milestones: (familyId: string) => `${familyName}/${familyId}/milestones`,
    goals: (familyId: string, milestoneId: string) => `${familyName}/${familyId}/milestones/${milestoneId}/goals`,
}

export const profilePaths = {
    user: `${profileName}`,
    familySettings: (profileId: string) => `${profileName}/${profileId}/familySettings`,
}

export const rewardsPaths = {
    rewards: (familyId: string) => `${familyName}/${familyId}/rewards`,
}

export const schedulesPaths = {
    chores: (familyId: string, date: Date) => `${familyName}/${familyId}/schedules/${toPathFromDate(date)}/chores`,
    activities: (familyId: string, date: Date) => `${familyName}/${familyId}/schedules/${toPathFromDate(date)}/activities`,
    meals: (familyId: string, date: Date) => `${familyName}/${familyId}/schedules/${toPathFromDate(date)}/meals`,
    week: (familyId: string) => `${familyName}/${familyId}/scheduledWeeks`,
}

export const recurringPaths = {
    chores: (familyId: string) => `${familyName}/${familyId}/recurring/commonData/chores`,
    activities: (familyId: string) => `${familyName}/${familyId}/recurring/commonData/activities`,
    meals: (familyId: string) => `${familyName}/${familyId}/recurring/commonData/meals`,

    recurringSelections: (familyId: string) => `${familyName}/${familyId}/recurring`,
}

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
export let toPathFromDate = (date: Date) => {
    let d = startOfDay(date);
    // logger.debug(`toPathFromDate: ${date.toISOString().split('T')[0]}`)
    // logger.debug(`toPathFromDate: ${d.toISOString().split('T')[0]}`)
    // logger.debug(`toPathFromDate: ${d.toISOString()}`)
    //expected output: 2011-10-05T14:48:00.000Z
    return d.toISOString().split('T')[0];
};

export const toWeekPathFromDate = (date: Date) => {
    let week = weekRange(date);
    let day = startOfDay(week.startOfWeek);

    let partialDateString = toPathFromDate(day);
    logger.debug(`CollectionPath:toWeekPathFromDate -> partialDateString=${partialDateString};`);
    return partialDateString;
}

