import {
    GoogleAuthProvider,
    signInWithPopup,
    UserCredential,
    OAuthCredential,
} from "firebase/auth";
import { Firebase } from "../../config";
import { intl } from "../../translations";
import { signInWith } from "./helpers";

//https://firebase.google.com/docs/auth/web/google-signin#web-version-9_1
const provider = new GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
Firebase.auth.useDeviceLanguage();

export function useGoogleAuthentication() {
    async function signInWithGoogle() {
        return signInWith(provider, (result) => GoogleAuthProvider.credentialFromResult(result));
    }

    return [signInWithGoogle] as const;
}
