import { DocumentReference } from "firebase/firestore";
import { ErrorObject } from "../../interfaces";


export function isError<T>(docProp: T | ErrorObject): docProp is ErrorObject {
    return docProp && (docProp as ErrorObject)?.error != null;
}


export function isDocRef<T>(docProp: T | DocumentReference<T>): docProp is DocumentReference<T> {
    return docProp && (docProp as DocumentReference<T>).firestore !== undefined;
}
