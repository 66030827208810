import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../assets/locales/en.json';
import { Logger } from "../logger";

// the translations
const resources = {
    en: {
        translation: translationEN
    }
};
let logger = new Logger('Intl');

export const initIntl = async () => {
    try {
        await i18n
            .use(initReactI18next) // passes i18n down to react-i18next
            .init({
                resources,
                lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
                // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
                // if you're using a language detector, do not define the lng option

                interpolation: {
                    escapeValue: false // react already safes from xss
                }
            });
    } catch (error) {
        logger.error(`initIntl failed to load.`, error);
        return false;
    }

    return true;
};

export function intl(name: string | string[]) {
    return i18n.t(name)
}

export const Intl = i18n;