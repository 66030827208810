import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DrawerProps } from '../interfaces';
import { Avatar, Box } from '@mui/material';
import { intl } from '../../../translations';
import { customTheme } from '../../../styling';


const DrawerHeaderContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: customTheme.colors.offWhite,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const DrawerHeaderContent = (props: DrawerProps) => {
    const { pic, displayName, onDrawerClose = () => { } } = props;
    const theme = useTheme();

    return (
        <DrawerHeaderContainer>
            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                gap: 1,
                justifyContent: 'start',
            }}>
                <Avatar
                    alt={displayName ?? intl('titles.guest')}
                    src={pic ?? ''}
                    sx={{
                        bgcolor: (theme) => theme.palette.secondary.main
                    }} />
                <Box component="span">{displayName}</Box>
            </Box>

            <IconButton onClick={onDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        </DrawerHeaderContainer>
    );
};

