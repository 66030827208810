import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Style } from '../styling';
import { AuthPage } from './partial';


export function LoginPage() {
    //------------------------------------------------
    // Renderers
    //------------------------------------------------
    return (
        <Box sx={{ display: 'flex' }} className="App">
            <Box component="main" sx={{ flexGrow: 1, p: Style.matGridMultiplier * 2, mt: Style.matGridMultiplier * 8 }}>
                <AuthPage></AuthPage>
            </Box>
        </Box>
    );
}