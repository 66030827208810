import { StatusType, SubscriptionEnum, SubscriptionType } from "../interfaces";

export interface SubscriptionDto {
    subscriptionType: SubscriptionType;
    status: StatusType;
    daysUntilDue: number;
    cancelledAt: number;
    cancelledAtEndBilling: boolean;
}

export const subDto: SubscriptionDto = {
    subscriptionType: SubscriptionEnum.Basic,
    status: StatusType.unknown,
    cancelledAt: -1,
    daysUntilDue: -1,
    cancelledAtEndBilling: false,
}
