import { Timestamp } from "./common";
import { FieldValue, serverTimestamp, Timestamp as FirebaseTimestamp } from "firebase/firestore";
import { fromTimestamp } from "../interfaces/mapping";
import { Logger } from "../logger";
// import * as firestore from "firebase/firestore";

let logger = new Logger('Date');

export type Timesource = FieldValue | Timestamp | string;

export const docDateFields = [
    'createdOn',
    'invitationSentOn',
    'modifiedOn',
    'subscriptionModifiedOn',
    'autoDisableOn',
];

export const docDeepFields = [
    'votingConfig',
];

export const toReduxTimestamp = (date: Timesource) => {
    if (typeof (date) === "string" || date == null) {
        return date ?? '';
    }
    let data: any = date;

    if (isServerTimestamp(date)) {
        if (date?.toDate) {
            return date.toDate()?.toISOString() ?? '';
        }
        return '';
    }

    if (!!(date as Timestamp)) {
        data = date as Timestamp;
        if (data) {
            let d = fromTimestamp(date as Timestamp);
            return d?.toISOString() ?? '';
        }
        return '';
    }

    return '';
}

export const toFirebaseTimestamp = (date: Timesource) => {
    if (!date) {
        return serverTimestamp();
    }

    if (isServerTimestamp(date)) {
        return date;
    }

    if (typeof (date) === "string") {
        let d = new Date(date);
        return FirebaseTimestamp.fromDate(d);
    }

    if (!!(date as Timestamp)) {
        let data: Timestamp | null = date as Timestamp;
        if (!data) {
            return serverTimestamp();
        }


        let miliseconds = data.seconds * 1000 + data.nanoseconds / 1000000;
        let d = new Date(miliseconds);
        return FirebaseTimestamp.fromDate(d);
    }

    return serverTimestamp();
}
// export const toFirestoreTimestamp = toFirebaseTimestamp;

// export const fromTimesource = (date: Timesource) => {
//     let dateString = toReduxTimestamp(date);
//     if (!dateString || !dateString.length) return null;

//     let d = new Date(dateString);
//     if (isNaN(d.valueOf())) return null;

//     return d;
// }

export function isServerTimestamp(date: Timesource): date is FirebaseTimestamp {
    if (!date) return false;
    let data: any = date;

    if ((data["_"] && data["_"]["_methodName"] == "FieldValue.serverTimestamp") || data['toDate'] || typeof (data['toDate']) === "function") {
        //logger.debug(`isServerTimestamp: data["_"]=${data["_"]}; data['toDate']=${data['toDate']} `, data)
        return true;
    } else {
        //logger.debug(`isServerTimestamp: data${data} `, data)
    }

    return false;
}

// export function hasServerTimestamp(map: any) {
//     for (let i = 0; i < docDateFields.length; i++) {
//         const key = docDateFields[i];

//         if (map[key]) {
//             let val = map[key];
//             if (isServerTimestamp(val)) {
//                 return true;
//             }
//         }
//     }

//     return false;
// }


// export function toTimestamp(d: Date): Timesource {
//     return d.toISOString();
//     // return {
//     //     nanoseconds: d.getTime() * 1000000,
//     //     seconds: d.getTime() / 1000,
//     // }
// }

// export const fromServerTimestamp = (timestamp: Timesource) => {
//     if (isServerTimestamp(timestamp)) {
//         return timestamp.toDate();
//     }
//     return fromCustomTimestamp(timestamp as Timestamp);
// }

// const fromTimestamp = (timestamp: Timestamp) => {
//     let miliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
//     return new Date(miliseconds);
//     // let d = Date.UTC(0, 0, 0, 0, 0, 0, miliseconds);
// }
// export const fromCustomTimestamp = fromTimestamp;