import { User } from "firebase/auth"
import { serverTimestamp } from "firebase/firestore"
import { AgeGroups, StatusType, SubscriptionEnum } from "../../../../interfaces"
import { Profile } from "../profile"

export const toProfile = (user: User): Profile => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return {
        ageGroup: AgeGroups.adult,
        accontStatus: StatusType.active,
        createdOn: serverTimestamp(),
        docId: '',
        modifiedOn: serverTimestamp(),
        name: user.displayName || 'Guest',
        picId: user.photoURL || '',
        userId: user.uid,
        subscriptionType: SubscriptionEnum.Basic,
        subscriptionModifiedOn: serverTimestamp(),
        timezone: timeZone,
        // familySettings: {}
        // settings: IHash
    }
}
