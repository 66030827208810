import axios from "axios";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthCredential, OAuthProvider, signInWithPopup, UserCredential } from "firebase/auth";
import { Firebase } from "../../config";
import { Endpoints } from "../../constants";
import { Logger } from "../../logger";
import { intl } from "../../translations";

Firebase.auth.useDeviceLanguage();
let logger = new Logger('Helper');

type Provider = GoogleAuthProvider | OAuthProvider | FacebookAuthProvider;
export type credentialFromResult = (result: UserCredential) => OAuthCredential | null;

export async function signInWith(provider: Provider, getCreds: credentialFromResult) {
    logger.debug(`Helper:signInWith: -> calling signInWithPopup`)

    try {
        logger.debug(`useGoogleAuthentication:signInWithGoogle: -> prompt user promptAsync`);
        const result: UserCredential = await signInWithPopup(Firebase.auth, provider);
        const credential: OAuthCredential | null = getCreds(result);

        logger.debug(`Helper:signInWith: -> calling signInWithPopup -> result=`, result)
        await beSignIn(credential);
        return {
            isSuccessful: true,
            user: result.user,
        };

    } catch (error: any) {
        logger.debug(`useGoogleAuthentication:onGoogleButtonPress: -> error=`, error);
        return {
            isSuccessful: false,
            displayMessage: intl('error.generic.login')
        };
    }
}

async function beSignIn(credential: OAuthCredential | null) {
    if (!credential) return;
    //https://www.digitalocean.com/community/tutorials/react-axios-react
    // If your app has a browser front end, use Google Sign-In as described in the Handle the sign-in flow manually section. Get the Google ID token from the auth response:
    const idToken = credential.idToken;
    return await authenticate(idToken);
}

export async function authenticate(idToken: string | undefined) {
    try {
        const response = await axios.get(Endpoints.authEndPoint, {
            //use the authorization
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        if (response && response.data && response.data.isValid) {
            return true;
        }

    } catch (error) {
        logger.warn('Authorization failed', error);
    }

    return false;
}
